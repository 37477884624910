import React, { useState, useEffect, useRef } from "react";
import { Package, Zap, Copy, Phone, Send, Video } from "react-feather";

import sendFile from "../_images/dashboard/sendFile.svg";
import compressFile from "../_images/dashboard/compressFile.svg";
import makeCall from "../_images/dashboard/makeCall.svg";
import presentFile from "../_images/dashboard/presentFile.svg";

import { Animated } from "react-animated-css";

import "../_css/dashboard.css";

// ///////////////////////////////////////////////////////////////////////////

export default function Dashboard(props) {
  // const [messageOpen, setMessageOpen] = useState(true);

  // //////////////////////////////////////////////////////////////////////////////

  // const testFunc = () => {
  //   console.log("testFunc");
  //   setMessageOpen(false);
  // };

  return (
    <div className="columnSection">
      <div id="dashboard">
        <h1>Currently closed to new projects</h1>
        {/* <div className="column4">
          <Animated
            animationIn="fadeInDownBig"
            animationInDelay={200}
            isVisible={true}
          >
            <a href="./new">
              <div className="dial presentFile">
                <span>Present a File</span>
                <div className="dashIcon">
                  <Copy />
                </div>
                <hr />
                <div className="dashDesc"></div>
                <img src={presentFile} className="dialBG" alt="presentFile" />
              </div>
            </a>
          </Animated>
        </div>

        <div className="column4">
          <Animated
            animationIn="fadeInDownBig"
            animationInDelay={400}
            isVisible={true}
          >
            <a href="./transfer">
              <div className="dial sendFile">
                <span>Transfer a Pres</span>
                <div className="dashIcon">
                  <Send />
                </div>
                <hr /> <div className="dashDesc"></div>
                <img src={sendFile} className="dialBG" alt="sendFile" />
              </div>
            </a>
          </Animated>
        </div> */}

        {/* <div className="column4">
          <Animated
            animationIn="fadeInDownBig"
            animationInDelay={600}
            isVisible={true}
          >
            <a href="./compress">
              <div className="dial compressFile">
                <span>Compress a File</span>
                <div className="dashIcon">
                  <Zap />
                </div>
                <hr /> <div className="dashDesc"></div>
                <img
                  src={compressFile}
                  className="dialBG"
                  style={{ top: "-65px", left: "-10px" }}
                  alt="compressFile"
                />
              </div>
            </a>
          </Animated>
        </div> */}

        {/* <div className="column4">
          <Animated
            animationIn="fadeInDownBig"
            animationInDelay={800}
            isVisible={true}
          >
            <a href="./new">
              <div className="dial makeCall">
                <span>Make a Call</span>
                <div className="dashIcon">
                  <Video />
                </div>
                <hr />
                <div className="dashDesc">Call desc goes here</div>
                <img src={makeCall} className="dialBG" alt="makeCall" />
              </div>
            </a>
          </Animated>
        </div> */}
      </div>
    </div>
  );
}

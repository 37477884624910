import React, { useState } from "react";

import ProjectOverview from "../account/projectOverview";
import CTA from "../callToAction.js";
import { Plus, X } from "react-feather";
import Loading from "../loading";

// Projects
// // All Projects
// // // Project Overview

// Account
// // Settings
// // Change Username

export default function Projects(props) {
  document.title = `Prescast - My Projects`;
  console.log(props);

  const [projectList, setProjectList] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  props.firebase.auth().onAuthStateChanged(function (user) {
    if (user && !user.isAnonymous) {
      console.log("signed in");

      if (!loggedIn) {
        setLoggedIn(true);
        props.firebase
          .firestore()
          .collection("user")
          .doc(user.uid)
          .onSnapshot((doc) => {
            if (doc.exists) {
              console.log("setProjectList");
              setProjectList(doc.data().projectList);
            } else {
              console.log("no Project List");

              setProjectList({});
            }
          });
      }
    } else {
      console.log("No user");
      return window.location.replace("/about");
    }
  });

  return loggedIn ? (
    <div>
      {console.log("projectList", projectList)}
      {projectList === undefined || Object.keys(projectList).length === 0 ? (
        <div className="projectOverview thumbConBlank">
          <span style={{ margin: "10px" }}>Oh you dont have anything.</span>
          <CTA text="Add something" trigger={"./new"} icon={<Plus />} />
        </div>
      ) : (
        Object.keys(projectList).map((value, index) => {
          return (
            <ProjectOverview firebase={props.firebase} projectID={value} />
          );
        })
      )}
    </div>
  ) : (
    <Loading />
  );
}

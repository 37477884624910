import React, { useState } from "react";
// import { BrowserRouter as Redirect } from 'react-router-dom';

import Header from "../../_components/header";
import AllProjects from "./allProjects";
import Dashboard from "../dashboard";

export default function Projects(props) {
  document.title = `Prescast - My Projects`;

  const [projectList, setProjectList] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);

  props.firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      console.log("signed in");

      props.firebase
        .firestore()
        .collection("user")
        .doc(user.uid)
        .onSnapshot((doc) => {
          if (doc.exists) {
            if (
              JSON.stringify(projectList) !==
              JSON.stringify(doc.data().projectList)
            ) {
              setProjectList(doc.data().projectList);
            }
          }
        });
    } else {
      console.log("No user");
      return window.location.replace("/about");
    }
  });

  return (
    <>
      <Header
        firebase={props.firebase}
        user={props.firebase.auth().currentUser}
      />

      <div id="main">
        <div className="section">
          <h2 className="sectionHeader">Create new</h2>
          <Dashboard />
        </div>
        <div className="section">
          <h2 className="sectionHeader">My Projects</h2>
          <AllProjects
            firebase={props.firebase}
            user={props.firebase.auth().currentUser}
          />
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";

// import CTA from "./callToAction.js";
// import { Plus, LogOut } from 'react-feather';
// import "../../_css/forms.css";

export default function Terms(props) {
  document.title = `Prescast - Terms`;

  // ///////////////////////////////////////////////////////////////

  return (
    <div>
      {/* HEADER //////////////////////////////////////////////////////////////// */}{" "}
      <br />
      <br />
      <br />
      <h3>The Legal Bits</h3>
      <br />
      <br />
      <a href="#terms">
        <h1>Terms and Conditions</h1>
      </a>
      <a href="#privacy">
        <h1>Privacy Policy</h1>
      </a>
      <a href="#cookie">
        {" "}
        <h1>Cookie Policy</h1>
      </a>
      <br /> <br /> <br />
      {/* TERMS //////////////////////////////////////////////////////////////// */}
      <a name="terms"></a>
      <h3>Terms and Conditions</h3>
      <br />
      Any questions please get in touch. Email us&nbsp;
      <a href="mailto:question@prescast.com?Subject=Question?&Body=I have a question...">
        here
      </a>
      <br />
      <br />
      <br />
      <br />
      <h1>Introduction</h1>
      <br />
      <p>
        These Website Standard Terms and Conditions written on this webpage
        shall manage your use of this website. These Terms will be applied fully
        and affect to your use of this Website. By using this Website, you
        agreed to accept all terms and conditions written in here. You must not
        use this Website if you disagree with any of these Website Standard
        Terms and Conditions.
      </p>
      <p>
        Minors or people below 18 years old are not allowed to use this Website.
      </p>
      <br />
      <h1>Intellectual Property Rights</h1>
      <br />
      <p>
        Other than the content you own, under these Terms, Prescast and/or its
        licensors own all the intellectual property rights and materials
        contained in this Website.
      </p>
      <p>
        You are granted limited license only for purposes of viewing the
        material contained on this Website.
      </p>
      <br />
      <h1>Restrictions</h1>
      <br />
      <p>You are specifically restricted from all of the following</p>
      <br />
      <ul>
        <li>publishing any Website material in any other media;</li>
        <li>
          selling, sublicensing and/or otherwise commercializing any Website
          material;
        </li>
        <li>publicly performing and/or showing any Website material;</li>
        <li>
          using this Website in any way that is or may be damaging to this
          Website;
        </li>
        <li>
          using this Website in any way that impacts user access to this
          Website;
        </li>
        <li>
          using this Website contrary to applicable laws and regulations, or in
          any way may cause harm to the Website, or to any person or business
          entity;
        </li>
        <li>
          engaging in any data mining, data harvesting, data extracting or any
          other similar activity in relation to this Website;
        </li>
        <li>using this Website to engage in any advertising or marketing.</li>
      </ul>
      <br />
      <p>
        Certain areas of this Website are restricted from being access by you
        and Prescast may further restrict access by you to any areas of this
        Website, at any time, in absolute discretion. Any user ID and password
        you may have for this Website are confidential and you must maintain
        confidentiality as well.
      </p>
      <br />
      <h1>Your Content</h1>
      <br />
      <p>
        In these Website Standard Terms and Conditions, “Your Content” shall
        mean any audio, video text, images or other material you choose to
        display on this Website. By displaying Your Content, you grant Prescast
        a non-exclusive, worldwide irrevocable, sub licensable license to use,
        reproduce, adapt, publish, translate and distribute it in any and all
        media.
      </p>
      <p>
        Your Content must be your own and must not be invading any third-party’s
        rights. Prescast reserves the right to remove any of Your Content from
        this Website at any time without notice.
      </p>
      <br />
      <h1>No warranties</h1>
      <br />
      <p>
        This Website is provided “as is,” with all faults, and Prescast express
        no representations or warranties, of any kind related to this Website or
        the materials contained on this Website. Also, nothing contained on this
        Website shall be interpreted as advising you.
      </p>
      <br />
      <h1>Limitation of liability</h1>
      <br />
      <p>
        In no event shall Prescast, nor any of its officers, directors and
        employees, shall be held liable for anything arising out of or in any
        way connected with your use of this{" "}
        <a href="https://premiumlinkgenerator.com/" target="_blank">
          Website
        </a>{" "}
        whether such liability is under contract. &nbsp;Prescast, including its
        officers, directors and employees shall not be held liable for any
        indirect, consequential or special liability arising out of or in any
        way related to your use of this Website.
      </p>
      <br />
      <h1>Indemnification</h1>
      <br />
      <p>
        You hereby indemnify to the fullest extent Prescast from and against any
        and/or all liabilities, costs, demands, causes of action, damages and
        expenses arising in any way related to your breach of any of the
        provisions of these Terms.
      </p>
      <br />
      <h1>Severability</h1>
      <br />
      <p>
        If any provision of these Terms is found to be invalid under any
        applicable law, such provisions shall be deleted without affecting the
        remaining provisions herein.
      </p>
      <br />
      <h1>Variation of Terms</h1>
      <br />
      <p>
        Prescast is permitted to revise these Terms at any time as it sees fit,
        and by using this Website you are expected to review these Terms on a
        regular basis.
      </p>
      <br />
      <h1>Assignment</h1>
      <br />
      <p>
        The Prescast is allowed to assign, transfer, and subcontract its rights
        and/or obligations under these Terms without any notification. However,
        you are not allowed to assign, transfer, or subcontract any of your
        rights and/or obligations under these Terms.
      </p>
      <br />
      <h1>User Data and Uploaded Data</h1>
      <br />
      <p>
        Data upload by users and user data are encrypted where necessary.
        Prescast will do everything within our power to protect user data and
        uploaded data. Prescast can not take any responsibility for data breches
        and lost data. Prescast should not be used as a mean of backing up data.
      </p>
      <br />
      <h1>Entire Agreement</h1>
      <br />
      <p>
        These Terms constitute the entire agreement between Prescast and you in
        relation to your use of this Website, and supersede all prior agreements
        and understandings.
      </p>
      <br />
      <h1>Governing Law &amp; Jurisdiction</h1>
      <br />
      <p>
        These Terms will be governed by and interpreted in accordance with the
        laws of the State of UK, and you submit to the non-exclusive
        jurisdiction of the state and federal courts located in UK for the
        resolution of any disputes.
      </p>
      <br />
      <br />
      <br />
      <h1>
        By using Prescast you agree to the terms and conditions, privacy policy
        and cookie policy viewed on this page.
      </h1>
      <br />
      <br />
      <br />
      Last Edited on 2020-01-01
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {/* PRIVACY //////////////////////////////////////////////////////////////// */}
      <a name="privacy"></a>
      <h3>Privacy Policy for Prescast</h3>
      <br />
      <br />
      This privacy policy has been compiled to better serve those who are
      concerned with how their 'Personally Identifiable Information' (PII) is
      being used online. PII, as described in US privacy law and information
      security, is information that can be used on its own or with other
      information to identify, contact, or locate a single person, or to
      identify an individual in context. Please read our privacy policy
      carefully to get a clear understanding of how we collect, use, protect or
      otherwise handle your Personally Identifiable Information in accordance
      with our website.
      <br />
      <br />
      <h2>
        What personal information do we collect from the people that visit our
        blog, website or app?
      </h2>
      <br />
      When ordering or registering on our site, as appropriate, you may be asked
      to enter your name, email address, mailing address, phone number, credit
      card information, social security number or other details to help you with
      your experience.
      <br />
      <br />
      <h2>When do we collect information?</h2>
      <br />
      We collect information from you when you register on our site, place an
      order, subscribe to a newsletter, respond to a survey, fill out a form,
      Use Live Chat, Open a Support Ticket or enter information on our site.
      Provide us with feedback on our products or services
      <br />
      <br />
      <h2>How do we use your information?</h2>
      <br />
      We may use the information we collect from you when you register, make a
      purchase, sign up for our newsletter, respond to a survey or marketing
      communication, surf the website, or use certain other site features in the
      following ways:
      <br />
      • To personalize your experience and to allow us to deliver the type of
      content and product offerings in which you are most interested.
      <br />
      • To improve our website in order to better serve you.
      <br />
      • To allow us to better service you in responding to your customer service
      requests.
      <br />
      • To administer a contest, promotion, survey or other site feature.
      <br />
      • To quickly process your transactions.
      <br />
      • To ask for ratings and reviews of services or products
      <br />
      • To follow up with them after correspondence (live chat, email or phone
      inquiries)
      <br />
      <br />
      <br />
      <h2>How do we protect your information?</h2>
      <br />
      Our website is scanned on a regular basis for security holes and known
      vulnerabilities in order to make your visit to our site as safe as
      possible. We do not use Malware Scanning. Your personal information is
      contained behind secured networks and is only accessible by a limited
      number of persons who have special access rights to such systems, and are
      required to keep the information confidential. In addition, all
      sensitive/credit information you supply is encrypted via Secure Socket
      Layer (SSL) technology. We implement a variety of security measures when a
      user places an order enters, submits, or accesses their information to
      maintain the safety of your personal information. All transactions are
      processed through a gateway provider and are not stored or processed on
      our servers.
      <br />
      <br />
      <h2>Do we use 'cookies'?</h2>
      <br />
      Yes. Cookies are small files that a site or its service provider transfers
      to your computer's hard drive through your Web browser (if you allow) that
      enables the site's or service provider's systems to recognize your browser
      and capture and remember certain information. For instance, we use cookies
      to help us remember and process the items in your shopping cart. They are
      also used to help us understand your preferences based on previous or
      current site activity, which enables us to provide you with improved
      services. We also use cookies to help us compile aggregate data about site
      traffic and site interaction so that we can offer better site experiences
      and tools in the future. We use cookies to:
      <br />
      • Help remember and process the items in the shopping cart.
      <br />
      • Understand and save user's preferences for future visits.
      <br />
      • Keep track of advertisements.
      <br />
      • Compile aggregate data about site traffic and site interactions in order
      to offer better site experiences and tools in the future. We may also use
      trusted third-party services that track this information on our behalf.
      <br />
      You can choose to have your computer warn you each time a cookie is being
      sent, or you can choose to turn off all cookies. You do this through your
      browser settings. Since browser is a little different, look at your
      browser's Help Menu to learn the correct way to modify your cookies.
      <br />
      <br />
      <h2>If users disable cookies in their browser:</h2>
      <br />
      If you turn cookies off, Some of the features that make your site
      experience more efficient may not function properly.Some of the features
      that make your site experience more efficient and may not function
      properly.
      <br />
      <br />
      <h2>Third-party disclosure</h2>
      <br />
      We do not sell, trade, or otherwise transfer to outside parties your
      Personally Identifiable Information.
      <br />
      <br />
      <h2>Third-party links</h2>
      <br />
      Occasionally, at our discretion, we may include or offer third-party
      products or services on our website. These third-party sites have separate
      and independent privacy policies. We therefore have no responsibility or
      liability for the content and activities of these linked sites.
      Nonetheless, we seek to protect the integrity of our site and welcome any
      feedback about these sites.
      <br />
      <br />
      <h2>Google</h2>
      <br />
      Google's advertising requirements can be summed up by Google's Advertising
      Principles. They are put in place to provide a positive experience for
      users. https://support.google.com/adwordspolicy/answer/1316548?hl=en We
      have not enabled Google AdSense on our site but we may do so in the
      future.
      <br />
      <br />
      <h2>California Online Privacy Protection Act</h2>
      <br />
      CalOPPA is the first state law in the nation to require commercial
      websites and online services to post a privacy policy. The law's reach
      stretches well beyond California to require any person or company in the
      United States (and conceivably the world) that operates websites
      collecting Personally Identifiable Information from California consumers
      to post a conspicuous privacy policy on its website stating exactly the
      information being collected and those individuals or companies with whom
      it is being shared. - See more at:
      http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
      <br />
      <br />
      <h2>According to CalOPPA, we agree to the following:</h2>
      <br />
      Users can visit our site anonymously. Once this privacy policy is created,
      we will add a link to it on our home page or as a minimum, on the first
      significant page after entering our website. Our Privacy Policy link
      includes the word 'Privacy' and can easily be found on the page specified
      above. You will be notified of any Privacy Policy changes:
      <br />
      • On our Privacy Policy Page
      <br />
      <br />
      Can change your personal information:
      <br />
      • By emailing us
      <br />
      • By logging in to your account
      <br />
      <br />
      <br />
      <h2>How does our site handle Do Not Track signals?</h2>
      <br />
      We honor Do Not Track signals and Do Not Track, plant cookies, or use
      advertising when a Do Not Track (DNT) browser mechanism is in place.
      <br />
      <br />
      <h2>Does our site allow third-party behavioral tracking?</h2>
      <br />
      It's also important to note that we allow third-party behavioral tracking
      <br />
      <br />
      <h2>COPPA (Children Online Privacy Protection Act)</h2>
      <br />
      When it comes to the collection of personal information from children
      under the age of 13 years old, the Children's Online Privacy Protection
      Act (COPPA) puts parents in control. The Federal Trade Commission, United
      States' consumer protection agency, enforces the COPPA Rule, which spells
      out what operators of websites and online services must do to protect
      children's privacy and safety online. We do not specifically market to
      children under the age of 13 years old. Do we let third-parties, including
      ad networks or plug-ins collect PII from children under 13?
      <br />
      <br />
      <h2>Fair Information Practices</h2>
      <br />
      The Fair Information Practices Principles form the backbone of privacy law
      in the United States and the concepts they include have played a
      significant role in the development of data protection laws around the
      globe. Understanding the Fair Information Practice Principles and how they
      should be implemented is critical to comply with the various privacy laws
      that protect personal information.
      <br />
      <br />
      <h2>
        In order to be in line with Fair Information Practices we will take the
        following responsive action, should a data breach occur:
      </h2>
      <br />
      We will notify you via email
      <br />
      • Within 7 business days
      <br />
      We also agree to the Individual Redress Principle which requires that
      individuals have the right to legally pursue enforceable rights against
      data collectors and processors who fail to adhere to the law. This
      principle requires not only that individuals have enforceable rights
      against data users, but also that individuals have recourse to courts or
      government agencies to investigate and/or prosecute non-compliance by data
      processors.
      <br />
      <br />
      <h2>CAN SPAM Act</h2>
      <br />
      The CAN-SPAM Act is a law that sets the rules for commercial email,
      establishes requirements for commercial messages, gives recipients the
      right to have emails stopped from being sent to them, and spells out tough
      penalties for violations.
      <br />
      <br />
      <h2>We collect your email address in order to:</h2>
      <br />
      • Send information, respond to inquiries, and/or other requests or
      questions
      <br />
      • Process orders and to send information and updates pertaining to orders.
      <br />
      • Send you additional information related to your product and/or service
      <br />
      • Market to our mailing list or continue to send emails to our clients
      after the original transaction has occurred.
      <br />
      <br />
      <br />
      <h2>To be in accordance with CANSPAM, we agree to the following:</h2>
      <br />
      • Not use false or misleading subjects or email addresses.
      <br />
      • Identify the message as an advertisement in some reasonable way.
      <br />
      • Include the physical address of our business or site headquarters.
      <br />
      • Monitor third-party email marketing services for compliance, if one is
      used.
      <br />
      • Honor opt-out/unsubscribe requests quickly.
      <br />
      • Allow users to unsubscribe by using the link at the bottom of each
      email.
      <br />
      <br />
      <br />
      <h2>
        If at any time you would like to unsubscribe from receiving future
        emails, you can Email us&nbsp; at
      </h2>
      <br />
      • Follow the instructions at the bottom of each email and we will promptly
      remove you from ALL correspondence.
      <br />
      <br />
      <h2>Contacting us</h2>
      <br />
      If there are any questions regarding this privacy policy, you may Email
      us&nbsp;{" "}
      <a href="mailto:question@prescast.com?Subject=Question?&Body=I have a question...">
        here
      </a>{" "}
      <br />
      <br />
      Last Edited on 2017-01-01
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {/* COOKIES //////////////////////////////////////////////////////////////// */}
      <a name="cookie"></a>
      <h3>Cookie Policy for Prescast</h3>
      <br />
      <br />
      <h2>What Are Cookies</h2>
      <br />
      As is common practice with almost all professional websites this site uses
      cookies, which are tiny files that are downloaded to your computer, to
      improve your experience. This page describes what information they gather,
      how we use it and why we sometimes need to store these cookies. We will
      also share how you can prevent these cookies from being stored however
      this may downgrade or 'break' certain elements of the sites functionality.
      <br />
      <br />
      For more general information on cookies see the
      <a href="http://en.wikipedia.org/wiki/HTTP_cookie">
        Wikipedia article on HTTP Cookies...
      </a>
      <br />
      <br />
      <br />
      <h2>How We Use Cookies</h2>
      <br />
      We use cookies for a variety of reasons detailed below. Unfortunately is
      most cases there are no industry standard options for disabling cookies
      without completely disabling the functionality and features they add to
      this site. It is recommended that you leave on all cookies if you are not
      sure whether you need them or not in case they are used to provide a
      service that you use.
      <br />
      <br />
      <br />
      <h2>Disabling Cookies</h2>
      <br />
      You can prevent the setting of cookies by adjusting the settings on your
      browser (see your browser Help for how to do this). Be aware that
      disabling cookies will affect the functionality of this and many other
      websites that you visit. Disabling cookies will usually result in also
      disabling certain functionality and features of the this site. Therefore
      it is recommended that you do not disable cookies.
      <br />
      <br />
      <br />
      <h2>The Cookies We Set</h2>
      <br />
      If you create an account with us then we will use cookies for the
      management of the signup process and general administration. These cookies
      will usually be deleted when you log out however in some cases they may
      remain afterwards to remember your site preferences when logged out.
      <br />
      <br />
      We use cookies when you are logged in so that we can remember this fact.
      This prevents you from having to log in every single time you visit a new
      page. These cookies are typically removed or cleared when you log out to
      ensure that you can only access restricted features and areas when logged
      in.
      <br />
      <br />
      This site offers newsletter or email subscription services and cookies may
      be used to remember if you are already registered and whether to show
      certain notifications which might only be valid to subscribed/unsubscribed
      users. <br />
      <br />
      This site offers e-commerce or payment facilities and some cookies are
      essential to ensure that your order is remembered between pages so that we
      can process it properly.
      <br />
      <br />
      From time to time we offer user surveys and questionnaires to provide you
      with interesting insights, helpful tools, or to understand our user base
      more accurately. These surveys may use cookies to remember who has already
      taken part in a survey or to provide you with accurate results after you
      change pages.
      <br />
      <br />
      When you submit data to through a form such as those found on contact
      pages or comment forms cookies may be set to remember your user details
      for future correspondence.
      <br />
      <br />
      In order to provide you with a great experience on this site we provide
      the functionality to set your preferences for how this site runs when you
      use it. In order to remember your preferences we need to set cookies so
      that this information can be called whenever you interact with a page is
      affected by your preferences.
      <br />
      <br />
      <br />
      <h2>Third Party Cookies</h2>
      <br />
      In some special cases we also use cookies provided by trusted third
      parties. The following section details which third party cookies you might
      encounter through this site.
      <br />
      <br />
      This site uses Google Analytics which is one of the most widespread and
      trusted analytics solution on the web for helping us to understand how you
      use the site and ways that we can improve your experience. These cookies
      may track things such as how long you spend on the site and the pages that
      you visit so we can continue to produce engaging content.
      <br />
      <br />
      For more information on Google Analytics cookies, see the
      <a href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies">
        official Google Analytics page
      </a>
      .<br />
      <br />
      Third party analytics are used to track and measure usage of this site so
      that we can continue to produce engaging content. These cookies may track
      things such as how long you spend on the site or pages you visit which
      helps us to understand how we can improve the site for you.
      <br />
      <br />
      From time to time we test new features and make subtle changes to the way
      that the site is delivered. When we are still testing new features these
      cookies may be used to ensure that you receive a consistent experience
      whilst on the site whilst ensuring we understand which optimisations our
      users appreciate the most.
      <br />
      <br />
      As we sell products it's important for us to understand statistics about
      how many of the visitors to our site actually make a purchase and as such
      this is the kind of data that these cookies will track. This is important
      to you as it means that we can accurately make business predictions that
      allow us to monitor our advertising and product costs to ensure the best
      possible price.
      <br />
      <br />
      The Google AdSense service we use to serve advertising uses a DoubleClick
      cookie to serve more relevant ads across the web and limit the number of
      times that a given ad is shown to you.
      <br />
      <br />
      For more information on Google AdSense see the
      <a href="http://www.google.co.uk/policies/privacy/ads/">
        official Google AdSense privacy FAQ
      </a>
      .<br />
      <br />
      We use adverts to offset the costs of running this site and provide
      funding for further development. The behavioural advertising cookies used
      by this site are designed to ensure that we provide you with the most
      relevant adverts where possible by anonymously tracking your interests and
      presenting similar things that may be of interest.
      <br />
      <br />
      In some cases we may provide you with custom content based on what you
      tell us about yourself either directly or indirectly by linking a social
      media account. These types of cookies simply allow us to provide you with
      content that we feel may be of interest to you.
      <br />
      <br />
      Several partners advertise on our behalf and affiliate tracking cookies
      simply allow us to see if our customers have come to the site through one
      of our partner sites so that we can credit them appropriately and where
      applicable allow our affiliate partners to provide any bonus that they may
      provide you for making a purchase.
      <br />
      <br />
      We also use social media buttons and/or plugins on this site that allow
      you to connect with your social network in various ways. For these to work
      the following social media sites including; Twitter, Facebook, Instagram,
      Tumblr, will set cookies through our site which may be used to enhance
      your profile on their site or contribute to the data they hold for various
      purposes outlined in their respective privacy policies.
      <br />
      <br />
      <br />
      <h2>More Information</h2>
      <br />
      Hopefully that has clarified things for you and as was previously
      mentioned if there is something that you aren't sure whether you need or
      not it's usually safer to leave cookies enabled in case it does interact
      with one of the features you use on our site. However if you are still
      looking for more information then you can contact us through one of our
      preferred contact methods.
      <br />
      <br />
      <strong>
        Email us&nbsp;
        <a href="mailto:question@prescast.com?Subject=Question?&Body=I have a question...">
          here
        </a>
      </strong>
      <br />
      <br />
      <br />
      Last Edited on 2017-01-01
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <a style={{ textAlign: "center !important" }}>
        Not find an anwser? Ask us&nbsp;
        <a href="mailto:question@prescast.com?Subject=Question?&Body=I have a question...">
          here
        </a>
      </a>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {/* FOOTER //////////////////////////////////////////////////////////////// */}
      <div id="footer">Prescast © 2020</div>
    </div>
  );
}

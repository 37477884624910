import React, { useState, useRef } from "react";
// import ReactDOM from "react-dom";

import CTA from "../callToAction.js";
import { Film, Grid, Settings, Trash2 } from "react-feather";
import Img from "react-image";
import Loading from "../loading";

import "../../_css/thumbs.css";

const Thumb = React.forwardRef((props, ref) => {
  console.log(props);

  if (props.blank) {
    return <div className="thumbBlank">Blank</div>;
  }

  if (props.loading) {
    return (
      <>
        <div className="thumb">
          <div className="thumbSettings">
            {!props.locked ? (
              <CTA
                icon={<Trash2 />}
                dark={true}
                trigger={() => {
                  console.log("DELETE");

                  props.deletePage(props.itemID);
                }}
              />
            ) : null}
          </div>
          <div className="thumbLoading">
            <Loading />
          </div>
        </div>
      </>
    );
  }

  if (props.itemData) {
    if (!props.itemData.smartPage) {
      var acceptedImages = [".tiff", ".jpeg", ".jpg", ".gif", ".png"];
      if (~acceptedImages.indexOf(props.itemData.ext)) {
        return (
          <div className="thumb">
            {props.projectID ? (
              <div className="thumbSettings">
                {!props.locked ? (
                  <CTA
                    icon={<Trash2 />}
                    dark={true}
                    trigger={() => {
                      console.log("DELETE");

                      props.deletePage(props.itemID);
                    }}
                  />
                ) : null}
              </div>
            ) : null}

            <Img
              src={props.itemData.url}
              container={(children) => {
                return (
                  <div
                    onClick={() => {
                      props.setPage(props.itemNumber + 1);
                    }}
                    ref={ref}
                    className={
                      props.highlighted ? "imgSelected" : "imgDeselected"
                    }
                  >
                    {children}
                  </div>
                );
              }}
            />
          </div>
        );
      }
    } else {
      return (
        <div
          className="thumb"
          onClick={() => {
            props.setPage(props.itemNumber + 1);
          }}
        >
          {props.projectID ? (
            <div className="thumbSettings">
              {!props.locked ? (
                <>
                  <CTA
                    icon={<Settings />}
                    dark={true}
                    trigger={
                      "./:" +
                      props.projectID +
                      "/edit/" +
                      props.itemID +
                      "/" +
                      props.itemData.type
                    }
                  />
                  <CTA
                    icon={<Trash2 />}
                    dark={true}
                    trigger={() => {
                      props.deletePage(props.itemID);
                    }}
                  />
                </>
              ) : null}
            </div>
          ) : null}
          <div
            className={props.highlighted ? "thumbSmart selected" : "thumbSmart"}
          >
            {(() => {
              switch (props.itemData.contentType) {
                case "video":
                  return <Film />;

                default:
                  return <Grid />;
              }
            })()}

            {/* {props.itemData.contentType} */}
          </div>
        </div>
      );
    }
  }

  return (
    <div className="thumbLoading">
      <Loading />
    </div>
  );
});

export default Thumb;

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
} from "react-router-dom";

import Header from "../_components/header.js";

import About from "../_components/about/about";

import Modal from "../_components/modal.js";

import LoginForm from "../_components/_auth/login";
import ForgotForm from "../_components/_auth/forgot";

import SignupForm from "../_components/_auth/signup";

import OpenProject from "../_components/project/openProject.js";

import Terms from "../_components/terms.js";

import Test from "../_components/test.js";
import CreateNewFolio from "../_components/createNewFolio";

import Cookies from "js-cookie";

// import * as ROUTES from '../routes.js';
import { withAuthentication } from "../_components/_auth/_session";
import { FirebaseContext } from "../_components/_firebase/";

import "../_css/brand.css";
import Message from "./message.js";
import PasswordChange from "./_auth/changePassword.js";
import queryString from "query-string";
import Account from "../_components/account/account";
import Projects from "../_components/account/projects";
import randomize from "randomatic";

import ReactGA from "react-ga";
ReactGA.initialize("UA-123509251-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => (
  <Router basename="/" forceRefresh={true}>
    <>
      <Switch>
        {/* SOCIAL REDIRECTS */}
        {/* ///////////////////////////////////////////// */}
        <Route path="/s41a2jg">
          {/* INSTAGRAM */}
          {() => {
            window.location.replace("/");
          }}
        </Route>
        <Route path="/ds67fsd">
          {/* TWITTER */}
          {() => {
            window.location.replace("/");
          }}
        </Route>
        <Route path="/linkedin">
          {/* LINKED IN */}
          {() => {
            window.location.replace("/");
          }}
        </Route>
        <Route path="/facebook">
          {/* FACEBOOK */}
          {() => {
            window.location.replace("/");
          }}
        </Route>
        <Route path="/pinterest">
          {/* PINTEREST */}
          {() => {
            window.location.replace("/");
          }}
        </Route>
        {/* DISCOVER */}
        {/* ///////////////////////////////////////////// */}
        <Route path="/discover">
          <FirebaseContext.Consumer>
            {(firebase) => (
              <Header firebase={firebase} user={firebase.auth().currentUser} />
            )}
          </FirebaseContext.Consumer>
          {/* <Message
            mainText="Check your email"
            subText="Didn't get anything? It can take a few mins. Also check your junk folder or try again"
            timeout={false}
            cta={'Done'}
            target={'/projects'}
          /> */}

          <Message
            // mainText="Password updated successfully."
            // subText="Loggin you in"
            timeout={true}
            // cta={'Done'}
            trigger={"projects"}
          />
        </Route>
        {/* ACCOUNT */}
        {/* ///////////////////////////////////////////// */}
        <Route path="/account">
          <FirebaseContext.Consumer>
            {(firebase) => <Account firebase={firebase} />}
          </FirebaseContext.Consumer>
        </Route>
        {/* PROJECTS */}
        {/* ///////////////////////////////////////////// */}
        <Route path="/projects">
          <FirebaseContext.Consumer>
            {(firebase) => <Projects firebase={firebase} />}
          </FirebaseContext.Consumer>
        </Route>
        {/* ABOUT */}
        {/* ///////////////////////////////////////////// */}
        <Route path="/about">
          <FirebaseContext.Consumer>
            {(firebase) => <About firebase={firebase} />}
          </FirebaseContext.Consumer>
        </Route>
        {/* LOGIN */}
        {/* ///////////////////////////////////////////// */}
        <Route path="/login">
          <Modal
            message={"Log in"}
            open={true}
            back={false}
            close={"./"}
            contents={
              <FirebaseContext.Consumer>
                {(firebase) => (
                  <LoginForm
                    firebase={firebase}
                    user={firebase.auth().currentUser}
                  />
                )}
              </FirebaseContext.Consumer>
            }
          />
        </Route>
        {/* FORGOT */}
        {/* ///////////////////////////////////////////// */}
        <Route path="/forgot">
          <Modal
            message={"Forgot"}
            open={true}
            back={false}
            close={"./"}
            contents={
              <FirebaseContext.Consumer>
                {(firebase) => (
                  <ForgotForm
                    firebase={firebase}
                    user={firebase.auth().currentUser}
                  />
                )}
              </FirebaseContext.Consumer>
            }
          />
        </Route>
        {/* ACTIONS */}
        {/* new password */}
        {/* new email */}
        {/* ///////////////////////////////////////////// */}
        <Route path="/action">
          {() => {
            var actionData = queryString.parse(document.location.search);

            switch (actionData.mode) {
              case "resetPassword":
                if (actionData.oobCode && actionData.apiKey) {
                  return (
                    <Modal
                      message={"Change Password"}
                      open={true}
                      back={false}
                      close={"./"}
                      contents={
                        <FirebaseContext.Consumer>
                          {(firebase) => (
                            <PasswordChange
                              firebase={firebase}
                              user={firebase.auth().currentUser}
                              actionData={actionData}
                            />
                          )}
                        </FirebaseContext.Consumer>
                      }
                    />
                  );
                } else {
                  return <h1>Error. Please try again.</h1>;
                }
              default:
                return <h1>Error. Please try again.</h1>;
            }
          }}
        </Route>
        {/* SIGN UP */}
        {/* ///////////////////////////////////////////// */}
        <Route path="/signup">
          <Modal
            message={"Sign up"}
            open={true}
            back={false}
            close={"./"}
            contents={
              <FirebaseContext.Consumer>
                {(firebase) => (
                  <SignupForm
                    firebase={firebase}
                    user={firebase.auth().currentUser}
                  />
                )}
              </FirebaseContext.Consumer>
            }
          />
        </Route>

        {/* NEW BLANK*/}
        {/* ///////////////////////////////////////////// */}
        <Route path="/new">
          {() => {
            window.location.replace("/:" + randomize("aA0", 8) + "/add/new");
          }}
        </Route>

        {/* NEW */}
        {/* ///////////////////////////////////////////// */}
        <Route path="/transfer">
          <FirebaseContext.Consumer>
            {(firebase) => {
              window.location.replace(
                "/:" + randomize("aA0", 8) + "/add/transfer"
              );
            }}
          </FirebaseContext.Consumer>
        </Route>

        {/* NEW */}
        {/* ///////////////////////////////////////////// */}
        <Route path="/compress">
          {() => {
            window.location.replace(
              "/:" + randomize("aA0", 8) + "/add/compress"
            );
          }}
        </Route>

        {/* CALL */}
        {/* ///////////////////////////////////////////// */}
        {/* <Route path="/call">
          {() => {
            window.location.replace("/:" + randomize("aA0", 8));
          }}
        </Route> */}

        {/* LOGOUT */}
        {/* ///////////////////////////////////////////// */}
        <Route path="/logout">
          <FirebaseContext.Consumer>
            {(firebase) => {
              Cookies.remove("PrescastUser");
              firebase
                .auth()
                .signOut()
                .then(function () {
                  window.location.replace("./");
                  // Sign-out successful.
                })
                .catch(function (error) {
                  // An error happened.
                  console.log(error);
                });
            }}
          </FirebaseContext.Consumer>
        </Route>

        {/* createNewFolio */}
        {/* ///////////////////////////////////////////// */}
        <Route path="/createNewFolio">
          <FirebaseContext.Consumer>
            {(firebase) => <CreateNewFolio firebase={firebase} />}
          </FirebaseContext.Consumer>
        </Route>
        {/* TEST */}
        {/* ///////////////////////////////////////////// */}
        <Route path="/test">
          <FirebaseContext.Consumer>
            {(firebase) => (
              <Test firebase={firebase} user={firebase.auth().currentUser} />
            )}
          </FirebaseContext.Consumer>
        </Route>
        {/* TERMS */}
        {/* ///////////////////////////////////////////// */}
        <Route path="/terms">
          <FirebaseContext.Consumer>
            {(firebase) => (
              <Header firebase={firebase} user={firebase.auth().currentUser} />
            )}
          </FirebaseContext.Consumer>{" "}
          <div id="main">
            <Terms />
          </div>
        </Route>
        {/* INDEX */}
        {/* ///////////////////////////////////////////// */}
        <Route
          path="/::projectID"
          children={
            <FirebaseContext.Consumer>
              {(firebase) => (
                <RedirectPres
                  firebase={firebase}
                  user={firebase.auth().currentUser}
                />
              )}
            </FirebaseContext.Consumer>
          }
        />
        <Route exact path="/">
          {() => {
            if (Cookies.get("PrescastUser")) {
              return window.location.replace("/projects");
              // window.location.reload();
            } else {
              console.log("NO PrescastUser");

              return (
                <FirebaseContext.Consumer>
                  {(firebase) => (
                    console.log(firebase),
                    (
                      <About
                        firebase={firebase}
                        user={firebase.auth().currentUser}
                      />
                    )
                  )}
                </FirebaseContext.Consumer>
              );
            }
          }}
        </Route>
        {/* 404 */}
        {/* ///////////////////////////////////////////// */}
        <Route path="*">
          <h1>404</h1>
        </Route>
      </Switch>
    </>
  </Router>
);

function RedirectPres(firebase) {
  let { projectID } = useParams();

  return <OpenProject projectID={projectID} firebase={firebase.firebase} />;
}

export default withAuthentication(App);

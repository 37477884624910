import React, { useEffect, useState } from "react";
import Modal from "./modal";
import CTA from "./callToAction";
import Loading from "./loading";
import { X, ArrowLeft, Check } from "react-feather";

// //////////////////////////////////////////////////////////////////////////////
// HOW TO?
// //////////////////////////////////////////////////////////////////////////////

// const [messageOpen, setMessageOpen] = useState(true);

// const testFunc = () => {
//   console.log("testFunc");
//   setMessageOpen(false);
// };

// return messageOpen ? (
//   <Message
//     mainText="Check your email"
//     subText="Didn't get anything? It can take a few mins. Also check your junk folder or try again."
//     timeout={true}
//     // cta={"Done"}
//     // trigger={"./"}
//     trigger={testFunc}
//   />

// //////////////////////////////////////////////////////////////////////////////
// //////////////////////////////////////////////////////////////////////////////

export default function Message(props) {
  if (props.timeout) {
    setTimeout(() => {
      console.log(props.trigger);
      console.log(typeof props.trigger);
      if (typeof props.trigger === "string") {
        window.location.replace(props.trigger);
      } else {
        props.trigger();
      }
    }, 1800);
  }

  return (
    <Modal
      message={<div style={{ textAlign: "center" }}>{props.mainText}</div>}
      open={true}
      back={false}
      close={false}
      contents={
        <>
          {props.subText ? (
            <p className="minor" style={{ textAlign: "center" }}>
              {props.subText}
            </p>
          ) : null}

          {props.cta ? (
            <div
              id="modalFooter"
              style={{ marginTop: "20px", textAlign: "center" }}
            >
              <div
                style={{
                  marginLeft: 7,
                  textAlign: "center",
                }}
              >
                <CTA text={props.cta} trigger={props.trigger} />
              </div>
            </div>
          ) : null}

          {props.timeout ? <Loading /> : null}
        </>
      }
    />
  );
}

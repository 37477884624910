import React, { useState, useRef, Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useLocation,
} from "react-router-dom";

import Modal from "../../_components/modal";

import Loading from "../loading";
import AgreeTerms from "../../_components/_auth/agreeTerms";
import { addToProjectList, setPresence, updateProject } from "./projectActions";

const Present = React.lazy(() => import("./present.js"));
const Share = React.lazy(() => import("./share.js"));
const OutputDownload = React.lazy(() => import("./download.js"));
const Add = React.lazy(() => import("./add/add"));
const Edit = React.lazy(() => import("./add/addSmart/smartForms/smartEdit"));

export default function OpenProject(props) {
  console.log(props.projectID);

  const [authStatus, setAuthStatus] = useState();
  const [project, setProject] = useState();
  const [userData, setUserData] = useState();
  const [projectMode, setProjectMode] = useState("loading");

  // blank
  // uploading

  useEffect(() => {
    // console.log(projectMode);

    if (userData) {
      // /////////////////////////////////////////////////
      // GET PROJECT

      props.firebase
        .firestore()
        .collection("content")
        .doc(props.projectID)
        .onSnapshot((doc) => {
          if (doc.exists) {
            if (project !== doc.data()) {
              var newData = doc.data();

              // SET PRES PRESETS
              if (!newData.pageNumber) {
                newData["pageNumber"] = 1;
              } else {
                // FIX A BROKEN PAGE NUMBER
                if (newData.pageNumber > newData.projectOrder.length) {
                  newData["pageNumber"] = 1;
                }
                if (newData.pageNumber < 1) {
                  newData["pageNumber"] = 1;
                }
                if (!Number.isInteger(newData.pageNumber)) {
                  newData["pageNumber"] = 1;
                }
                // ////////////////////////////////
              }

              // if(){}

              setProject(newData);
            }
          } else {
            // PROJECT DOES NOT EXISTS
            setProjectMode("blank");
          }
        });

      // /////////////////////////////////////////////////
      // SET PRESENCE
      // setPresence(props.firebase, props.projectID, props.userData);
    }
  }, [userData]);

  useEffect(() => {
    console.log(projectMode);
    console.log(project);
    console.log(userData);

    if (projectMode === "blank" && !project && userData) {
      console.log("INIT PROJECT");

      updateProject(props.firebase, props.projectID, {
        creators: [userData.publicID],
        controller: userData.publicID,
      });
    }
  }, [projectMode]);

  useEffect(() => {
    console.log("project updated", project);

    if (project) {
      if (!project.projectOrder || project.projectOrder.length < 1) {
        // EMPTY PROJECT
        setProjectMode("blank");
      } else {
        // PRESENT PROJECT
        setProjectMode("present");
      }
    } else {
      // STILL LOADING
      setProjectMode("loading");
    }
  }, [project]);

  const getInit = useRef(false);

  props.firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // console.log("User Auth");
      if (authStatus !== true) {
        setAuthStatus(true);
      }
    } else {
      // console.log("No User");
      setAuthStatus(false);
    }
  });

  switch (authStatus) {
    case true:
      if (!getInit.current) {
        // console.log(add());

        // /////////////////////////////////////////////////
        // GET USER
        props.firebase
          .firestore()
          .collection("user")
          .doc(props.firebase.auth().currentUser.uid)
          .onSnapshot((doc) => {
            if (doc.exists) {
              // console.log("USER DATA EXISTS");
              // console.log(userData);
              // console.log(doc.data());

              if (userData !== doc.data()) {
                // console.log("UPDATE USER DATA");

                // console.log(doc.data());
                setUserData(doc.data());
              }
            } else {
              // console.log("USER DOES NOT EXISTS");
              setUserData({ projectList: {} });
            }
            addToProjectList(props.firebase, props.projectID, doc.data());
          });

        getInit.current = true;
      }

      return (
        <Router>
          <Switch>
            {/* PRESENT */}
            {/* ///////////////////////////////////////////// */}
            <Route
              exact
              path="/::projectID"
              children={
                <Suspense fallback={<Loading mode="fullscreen" />}>
                  <Present
                    firebase={props.firebase}
                    userData={userData}
                    projectID={props.projectID}
                    project={project}
                    projectMode={projectMode}
                    setProjectMode={setProjectMode}
                    locked={project ? project.locked : true}
                  />
                </Suspense>
              }
            />
            {/* SHARE */}
            {/* ///////////////////////////////////////////// */}
            <Route
              path="/::projectID/share"
              children={
                <Modal
                  message={"Securely share"}
                  back={false}
                  open={true}
                  close={"/:" + props.projectID}
                  contents={
                    <Suspense fallback={<Loading mode="fullscreen" />}>
                      <Share projectID={props.projectID} mode={false} />
                    </Suspense>
                  }
                />
              }
            />

            {/* TRANSFER */}
            {/* ///////////////////////////////////////////// */}
            <Route
              path="/::projectID/transfer"
              children={
                <Modal
                  message={"Transfer"}
                  back={false}
                  close={"/:" + props.projectID}
                  open={true}
                  contents={
                    <Suspense fallback={<Loading mode="fullscreen" />}>
                      <OutputDownload
                        firebase={props.firebase}
                        projectID={props.projectID}
                        project={project}
                        mode="transfer"
                      />
                    </Suspense>
                  }
                />
              }
            />

            {/* COMPRESS */}
            {/* ///////////////////////////////////////////// */}
            <Route
              path="/::projectID/compress"
              children={
                <Modal
                  message={"Compressed"}
                  back={false}
                  close={"/:" + props.projectID}
                  open={true}
                  contents={
                    <Suspense fallback={<Loading mode="fullscreen" />}>
                      <OutputDownload
                        firebase={props.firebase}
                        projectID={props.projectID}
                        project={project}
                        mode="compress"
                      />
                    </Suspense>
                  }
                />
              }
            />

            {/* DOWNLOAD */}
            {/* ///////////////////////////////////////////// */}
            <Route
              path="/::projectID/download"
              children={
                <Modal
                  message={"Download"}
                  back={false}
                  close={"/:" + props.projectID}
                  open={true}
                  contents={
                    <Suspense fallback={<Loading mode="fullscreen" />}>
                      <OutputDownload
                        firebase={props.firebase}
                        projectID={props.projectID}
                        project={project}
                        mode="download"
                      />
                    </Suspense>
                  }
                />
              }
            />
            {/* ADD */}
            {/* ///////////////////////////////////////////// */}
            <Route
              path="/::projectID/add"
              children={
                <Suspense fallback={<Loading mode="fullscreen" />}>
                  <Add
                    projectID={props.projectID}
                    project={project}
                    firebase={props.firebase}
                    userData={userData}
                    trigger={"/:" + props.projectID}
                    close={"/:" + props.projectID}
                    back={() => {
                      window.location.href = "/";
                    }}
                  />
                </Suspense>
              }
            />
            {/* EDIT */}
            {/* ///////////////////////////////////////////// */}
            <Route
              path="/::projectID/edit"
              children={
                <Modal
                  message={"Edit"}
                  fullscreen={true}
                  close={false}
                  open={true}
                  done={"/:" + props.projectID}
                  contents={
                    <Suspense fallback={<Loading />}>
                      <Edit
                        projectID={props.projectID}
                        project={project}
                        firebase={props.firebase}
                        back={"/:" + props.projectID + "/add"}
                      />
                    </Suspense>
                  }
                />
              }
            />
          </Switch>
        </Router>
      );

    case false:
      return (
        <Modal
          message={"Terms"}
          open={true}
          close={"../"}
          contents={
            <AgreeTerms
              firebase={props.firebase}
              user={props.firebase.auth().currentUser}
            />
          }
        />
      );

    default:
      return <Loading mode="fullscreen" />;
  }
}

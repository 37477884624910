export const INDEX = "/";
export const TEST = "/test";
export const TERMS = "/terms";
export const ABOUT = "/about";
export const DISCOVER = "/discover";

export const PROJECTS = "/projects";
export const ACCOUNT = "/account";
export const ACTION = "/action";

export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const FORGOT = "/forgot";

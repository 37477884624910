import React, { useState, useEffect, useRef } from "react";
// import { BrowserRouter as Redirect } from 'react-router-dom';

import CTA from "../callToAction.js";
// import { Plus, LogOut } from 'react-feather';

import KeyboardEventHandler from "react-keyboard-event-handler";

import { valEmail, valUsername, valPassword } from "../_auth/authActions";

import { Link } from "react-router-dom";
import * as ROUTES from "../../routes";
import "../../_css/forms.css";
import Cookies from "js-cookie";

export default function SignupForm(props) {
  document.title = `Prescast - Sign up`;

  const [loggedIn, setLoggedIn] = useState();
  const [attempt, setAttempt] = useState(false);

  const [inputEmail, setInputEmail] = useState({
    valid: false,
    feedback: "",
    value: "",
  });

  const [inputUsername, setInputUsername] = useState({
    valid: false,
    feedback: "",
    value: "",
  });

  const [inputPassword, setInputPassword] = useState({
    valid: false,
    feedback: "",
    value: "",
  });

  const [formFeedback, setFormFeedback] = useState("");

  let passwordRef = React.createRef();
  let usernameRef = React.createRef();
  let emailRef = React.createRef();

  const handleKeyDown = (key, e) => {
    e.preventDefault();
    if (key === "enter") {
      console.log("handleKeyDown");
      console.log(key);

      // submitSignup();
    }
  };

  const initUser = useRef(false);

  props.firebase.auth().onAuthStateChanged(function (user) {
    console.log("onAuthStateChanged");
    if (user) {
      if (!initUser.current) {
        if (!user.isAnonymous && loggedIn === undefined) {
          console.log("onAuthStateChanged - user valid");
          setLoggedIn(true);
        } else {
          console.log("onAuthStateChanged - isAnonymous");
        }

        initUser.current = true;
      }
    } else {
      console.log("onAuthStateChanged - no user");

      setLoggedIn(false);
    }
  });

  // ///////////////////////////////////////////////////////////////

  const guessUsername = () => {
    console.log("guessUsername");
    console.log(usernameRef.current.value);

    if (valEmail(emailRef.current.value) && usernameRef.current.value === "") {
      var guess = emailRef.current.value.split("@")[0];
      setInputUsername({
        valid: false,
        feedback: "",
        value: guess,
      });

      usernameRef.current.value = guess;
      checkVal();
    }
  };

  const checkVal = () => {
    console.log("checkVal");

    ////////// EMAIL VALID?
    setInputEmail(valEmail(emailRef.current.value));

    ////////// USERNAME VALID?
    setInputUsername(valUsername(usernameRef.current.value));

    ////////// PASSWORD VALID?
    setInputPassword(valPassword(passwordRef.current.value));
  };

  const submitSignup = () => {
    console.log("submitSignup");

    console.log(emailRef);
    console.log(passwordRef);
    console.log(attempt);

    // INITAL?
    setAttempt(true);
    console.log(attempt);

    checkVal();

    console.log(props);
    console.log(props.firebase.auth().currentUser);

    // ALL VALID
    if (
      inputEmail.valid === true &&
      inputPassword.valid === true &&
      inputUsername.valid === true
    ) {
      console.log(props);

      if (
        props.firebase.auth().currentUser !== null &&
        props.firebase.auth().currentUser.isAnonymous
      ) {
        // SIGN UP - UPGRADE ANONYMOUS USER

        console.log("SIGN UP - UPGRADE ANONYMOUS USER");

        // props.firebase.test2(inputEmail.value, inputPassword.value);
        var credential = props.firebase.auth.EmailAuthProvider.credential(
          inputEmail.value,
          inputPassword.value
        );

        props.firebase
          .auth()
          .currentUser.linkWithCredential(credential)
          .then(function (usercred) {
            console.log("Anonymous account successfully upgraded", usercred);

            // SAVE USERNAME
            props.firebase
              .auth()
              .currentUser.updateProfile({
                displayName: inputUsername.value,
              })
              .then(() => {
                setLoggedIn(true);
              });
          })
          .catch(function (error) {
            console.log("Error upgrading anonymous account", error);
            switch (error.code) {
              case "auth/user-not-found":
                setFormFeedback("Oh... we dont have that email");
                break;
              case "auth/wrong-password":
                setFormFeedback("Nope! That's not right");
                break;
              default:
                setFormFeedback("Nope! That's not right");
            }
          });
      } else {
        // SIGN UP - NOT ANONYMOUS USER

        console.log(props);
        console.log("SIGN UP - NOT ANONYMOUS USER");

        props.firebase
          .auth()
          .createUserWithEmailAndPassword(inputEmail.value, inputPassword.value)
          .then(() => {
            // SAVE USERNAME
            console.log("Sign up request SENT");

            props.firebase
              .auth()
              .currentUser.updateProfile({
                displayName: inputUsername.value,
              })
              .then(() => {
                setLoggedIn(true);
              });
          })
          .catch(function (error) {
            console.log(error);

            switch (error.code) {
              case "auth/email-already-in-use":
                setInputEmail({
                  valid: false,
                  feedback: "Oh... that account already exists",
                  value: inputEmail.value,
                });
                break;
              default:
                setFormFeedback("Nope! That's not right");

                setInputEmail({
                  valid: false,
                  feedback: "",
                  value: inputEmail.value,
                });
                setInputUsername({
                  valid: false,
                  feedback: "",
                  value: inputUsername.value,
                });
                setInputPassword({
                  valid: false,
                  feedback: "",
                  value: inputPassword.value,
                });
            }
          });
      }
    }
  };

  return !loggedIn
    ? [
        <KeyboardEventHandler
          handleFocusableElements={true}
          handleKeys={["esc", "enter", "return"]}
          onKeyEvent={(key, e) => handleKeyDown(key, e)}
        />,
        <div>
          <br />

          <form autoComplete="off">
            <input name="email" type="email" style={{ display: "none" }} />
            <input
              name="password"
              type="password"
              style={{ display: "none" }}
            />

            <label
              className={
                inputEmail.valid === false &&
                inputEmail.feedback !== "" &&
                attempt === true
                  ? "invalid"
                  : ""
              }
            >
              {attempt ? inputEmail.feedback : ""}
            </label>

            <input
              name="email"
              ref={emailRef}
              className={
                inputEmail.valid == false && attempt == true ? "invalid" : ""
              }
              onChange={checkVal}
              onBlur={guessUsername}
              type="text"
              autoFocus
              autoComplete="off"
              placeholder="your@email.com"
            />

            <label
              className={
                inputUsername.valid === false &&
                inputUsername.feedback !== "" &&
                attempt === true
                  ? "invalid"
                  : ""
              }
            >
              {attempt ? inputUsername.feedback : ""}
            </label>

            <input
              name="username"
              ref={usernameRef}
              className={
                inputUsername.valid == false && attempt == true ? "invalid" : ""
              }
              onChange={checkVal}
              type="text"
              autoComplete="off"
              placeholder="username"
            />

            <br />
            <br />
            <br />

            <label
              className={
                inputPassword.valid === false &&
                inputPassword.feedback !== "" &&
                attempt === true
                  ? "invalid"
                  : ""
              }
            >
              {attempt ? inputPassword.feedback : ""}
            </label>

            <input
              name="new-password"
              ref={passwordRef}
              autoComplete="new-password"
              className={
                inputPassword.valid == false && attempt == true ? "invalid" : ""
              }
              onChange={checkVal}
              type="password"
              placeholder="password"
            />

            <div id="modalFooter">
              <div id="feedback">{formFeedback ? formFeedback : null}</div>

              <span className="minor">
                By signing up you agree to our
                <br />
                <a href="./terms" target="_new">
                  policy, terms and conditions
                </a>
              </span>

              <br />
              <br />
              <br />
              <div style={{ float: "right", marginLeft: 7 }}>
                <CTA text="Sign up" trigger={submitSignup} />
              </div>
              <Link
                to={ROUTES.LOGIN}
                className="nextToButtonRight"
                style={{ float: "right" }}
              >
                Log in
              </Link>
            </div>
          </form>
        </div>,
      ]
    : window.location.replace("/projects");
}

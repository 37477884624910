import validator from "validator";

export function valEmail(email) {
  console.log("valEmail");
  console.log(email);

  // EMPTY
  if (!email) {
    return {
      valid: false,
      feedback: "",
      value: email,
    };
  }

  if (validator.isEmail(email)) {
    // VALID
    return {
      valid: true,
      feedback: "",
      value: email,
    };
  } else {
    // NOT VALID
    return {
      valid: false,
      feedback: "That's not right",
      value: email,
    };
  }
}

export function valPassword(password) {
  console.log("valPassword");
  console.log(password);

  // EMPTY
  if (!password) {
    return {
      valid: false,
      feedback: "",
      value: password,
    };
  }
  if (
    !validator.isLength(password, {
      min: 8,
      max: 50,
    })
  ) {
    return {
      valid: false,
      feedback: "Oops! 8-50 characters please.",
      value: password,
    };
  }

  return {
    valid: true,
    feedback: "",
    value: password,
  };
}

export function valUsername(username) {
  console.log("valUsername");
  console.log(username);

  // EMPTY
  if (!username) {
    return {
      valid: false,
      feedback: "",
      value: username,
    };
  }
  if (
    !validator.isLength(username, {
      min: 5,
      max: 20,
    })
  ) {
    return {
      valid: false,
      feedback: "Oops! 5-20 characters please.",
      value: username,
    };
  }

  if (
    !validator.isWhitelisted(
      username,
      "abcdefghijklmnopqrstuvwxyzabcçddheëfggjhijklllmnnjopqrrrsshtthuvxxhyzzhabcčćddžđefghijklljmnnjoprsšuvzžabcčdefghchijklmnoprřsštuvwxyzžšžõäöüabcdefghijklmnopqrsšzžtuvwõäöüxyaábdðefghiíjklmnoóprstuúvyýæøáÐíóúýøabcdefghijklmnopqrstuvxyzäöABCČĆDĐEFGHIJKLMNOPQRSŠTUVWXYZŽabcčćdđefghijklmnopqrsštuvwxyzžआईऊऋॠऌॡऐऔअंअँकखगघङचछजझञटठडढणतथदधनपफबभयरवळशषसह1234567890०१२३४५६७८९₹Çéâêîôûàèùëïü.+'-_&1234567890"
    )
  ) {
    return {
      valid: false,
      feedback: "Basic characters only please.",
      value: username,
    };
  }

  return {
    valid: true,
    feedback: "",
    value: username,
  };
}

export function valNotEmpty(value) {
  console.log("valNotEmpty");
  console.log(value);

  // EMPTY
  if (!value) {
    return {
      valid: false,
      feedback: "",
      value: "",
    };
  } else {
    return {
      valid: true,
      feedback: "",
      value: value,
    };
  }
}

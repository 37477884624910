import screenfull from "screenfull";

export function checkControl(project, userData, accept, control) {
  console.log("checkControl");

  if (userData.publicID === project.controller) {
    accept();
  } else {
    control();
  }
}

export function takeControl(firebase, projectID, project, userData, done) {
  console.log("takeControl");
  console.log(project);
  console.log(userData);
  console.log(userData.publicID);
  console.log(project.controller);

  project.controller = userData.publicID;
  updateProject(firebase, projectID, project);

  done();
}

export function checkUploadProcess(project) {
  console.log("checkUploadProcess");
  console.log(project);

  if (project) {
    // CHECK PROJECT ORDER EXISTS IN ITEM LIST
    var checkProjectOrder = project.projectOrder.map((itemID, index) => {
      if (project.itemList[itemID]) {
        return true;
      } else {
        console.log(itemID);
        return false;
      }
    });

    // THEN CHECK ALL IN ITEM LIST
    var checkItemList = Object.values(project.itemList).map((item, index) => {
      if (item.uploadProcessed === true) {
        return true;
      } else {
        console.log(item);
        return false;
      }
    });

    if (checkProjectOrder.includes(false) || checkItemList.includes(false)) {
      return false;
    } else {
      return true;
    }
  }
}

export function updateProject(firebase, projectID, projectData) {
  console.log("UPDATE PROJECT");

  firebase
    .firestore()
    .collection("content")
    .doc(projectID)
    .set(projectData, { merge: true })
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function updateUser(firebase, userData) {
  console.log("UPDATE USER");

  firebase
    .firestore()
    .collection("user")
    .doc(firebase.auth().currentUser.uid)
    .set(userData, { merge: true });
}

export function nextPage(firebase, projectID, project) {
  if (project.pageNumber < project.projectOrder.length) {
    console.log("nextPage");

    project.pageNumber = project.pageNumber + 1;
    updateProject(firebase, projectID, project);
  }
}

export function prevPage(firebase, projectID, project) {
  if (project.pageNumber > 0) {
    console.log("prevPage");

    project.pageNumber = project.pageNumber - 1;
    updateProject(firebase, projectID, project);
  }
}

export function setPage(firebase, projectID, project, newPage) {
  console.log(firebase);
  console.log(projectID);
  console.log(project);
  console.log(newPage);

  if (project.pageNumber !== newPage) {
    console.log("SET PAGE");

    project.pageNumber = newPage;
    updateProject(firebase, projectID, project);
  }
}

export function deletePage(firebase, projectID, project, deleteItemID) {
  // DELETE FROM PROJECT ORDER
  console.log(project.projectOrder);

  project.projectOrder.splice(project.projectOrder.indexOf(deleteItemID), 1);

  // MARK IN FILE LIST AS DELETED
  project.itemList[deleteItemID].deleted = true;

  if (project.itemList[deleteItemID + "_LR"]) {
    project.itemList[deleteItemID + "_LR"].deleted = true;
  }
  if (project.itemList[deleteItemID + "_MR"]) {
    project.itemList[deleteItemID + "_MR"].deleted = true;
  }

  // IF CURRENT PAGE IS GREATER THAN PAGE SET TO LAST PAGE
  if (project.pageNumber > project.projectOrder.length) {
    project.pageNumber = project.projectOrder.length;
  }

  // UPDATE PROJECT
  updateProject(firebase, projectID, project);
}

export function toogleFullscreen() {
  console.log("toogleFullscreen");

  if (screenfull.isEnabled) {
    screenfull.toggle();
  }

  screenfull.on("change", () => {
    console.log("fullscreen?", screenfull.isFullscreen ? "Yes" : "No");
  });
}
export function addToProjectList(firebase, projectID, userData) {
  if (userData) {
    if (!userData.projectList) {
      userData["projectList"] = [];
    }
    console.log(firebase);
    console.log(projectID);
    console.log(userData);
    console.log(userData.projectList);
    console.log(Object.keys(userData.projectList));

    if (!Object.keys(userData.projectList).includes(projectID)) {
      console.log("LINKING PROJECT NOW");
      userData.projectList[String(projectID)] = {};

      updateUser(firebase, userData);
    }
  }
}

export function setPresence(firebase, projectID, userData) {
  var userPresenceRef = firebase
    .database()
    .ref("/" + projectID + "/" + userData.publicID);

  firebase
    .database()
    .ref(".info/connected")
    .on("value", function (snapshot) {
      if (snapshot.val() == false) {
        return;
      }
      userPresenceRef
        .onDisconnect()
        .remove()
        // .set({
        //   state: "offline",
        //   lastActive: firebase.database.ServerValue.TIMESTAMP,
        // })
        .then(function () {
          userPresenceRef.set({
            state: "online",
            lastActive: firebase.database.ServerValue.TIMESTAMP,
            username: userData.username,
            video: userData.video,
            audio: userData.audio,
          });
        });
    });
}

export async function functionRequest(type, postData) {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();

    xhr.open(
      "POST",
      process.env.REACT_APP_CLOUD_FUNCTION_BASE_URL + "/" + type,
      true
    );

    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    // xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function () {
      if (xhr.readyState == XMLHttpRequest.DONE && xhr.status == 200) {
        resolve(xhr.responseText);
      }

      if (xhr.readyState == XMLHttpRequest.DONE && xhr.status == 400) {
        console.log("error");

        console.log(xhr);
        console.log(xhr.responseText);

        resolve(xhr.responseText);
      }
    };

    xhr.send(JSON.stringify(postData));
  }).catch((error) => {
    console.log(error);
  });
}

// function twilioToken() {
//   console.log("get twilioToken");
//   let promise = new Promise(function(resolve, reject) {
//     var xhr = new XMLHttpRequest();
//     xhr.open(
//       "POST",
//       "https://us-central1-wepresit.cloudfunctions.net/twilioAccess",
//       true
//     );
//     xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
//     xhr.onreadystatechange = function() {
//       if (xhr.readyState == XMLHttpRequest.DONE && xhr.status == 200) {
//         resolve(xhr.responseText);
//       }
//     };

//     xhr.send(
//       "userIdentity=" +
//         String(userInfo.publicID) +
//         "&projectID=" +
//         String(projectID)
//     );
//   });

//   promise.then(
//     result => {
//       console.log(result);
//       token = result;
//       userInfo["projectList"] = { [projectID]: token };
//       updateUserInfo();
//       setPresence();
//     },
//     error => console.log(error)
//   );
// }

import React, { useState } from "react";

import { Link } from "react-router-dom";
import * as ROUTES from "../routes.js";

import logo from "../_images/logo.svg";

import CTA from "./callToAction.js";
import { Plus } from "react-feather";
import Cookies from "js-cookie";

// import { isMobile } from "react-device-detect";

export default function Header(props) {
  console.log(props);
  const [loggedIn, setLoggedIn] = useState();

  props.firebase.auth().onAuthStateChanged(function (user) {
    console.log(user);
    if (user && !user.isAnonymous) {
      Cookies.set("PrescastUser", true, { expires: 365 });
      // Cookies.set("PrescastTerms", true, { expires: 365 });

      setLoggedIn(true);
    } else {
      Cookies.remove("PrescastUser");
      // Cookies.remove("PrescastTerms");

      console.log("No user");
      setLoggedIn(false);
    }
  });

  return (
    <div id="header">
      <Link to={ROUTES.INDEX} key="index" style={{ padding: "0px" }}>
        <img src={logo} id="logo" alt="logo" />
      </Link>

      <div id="nav">
        {/* <CTA
          text="New"
          icon={<Plus />}
          // trigger="pres"
          trigger={"./new"}
        /> */}

        {(() => {
          switch (loggedIn) {
            case true:
              return [
                <Link to={ROUTES.PROJECTS} key="projects">
                  Projects
                </Link>,
                // <Link to={ROUTES.DISCOVER} key="discover">
                //   Discover
                // </Link>,

                <Link to={ROUTES.ACCOUNT} key="account">
                  Account
                </Link>,

                <Link to={ROUTES.ABOUT} key="about">
                  About
                </Link>,
              ];
            case false:
              return [
                <Link to={ROUTES.LOGIN} key="login">
                  Log in
                </Link>,
                <Link to={ROUTES.SIGNUP} key="signup">
                  Sign up
                </Link>,
                // <Link to={ROUTES.DISCOVER} key="discover">
                //   Discover
                // </Link>,
                <Link to={ROUTES.ABOUT} key="about">
                  About
                </Link>,
              ];
          }
        })()}
      </div>
    </div>
  );
}

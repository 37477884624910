import React, { useEffect } from "react";
import "../_css/modal.css";
import { X, ArrowLeft, Check } from "react-feather";

import CTA from "./callToAction.js";

export default function Modal(props) {
  // const [projectList, setProjectList] = useState({});

  return props.open ? (
    <div id="modal" className={props.fullscreen ? "modalFullscreen" : ""}>
      <div className="back">
        {props.back ? (
          <CTA icon={<ArrowLeft />} minor={true} trigger={props.back} />
        ) : null}
      </div>
      {props.close ? (
        <div className="close">
          <CTA icon={<X />} minor={true} trigger={props.close} />
        </div>
      ) : null}
      {/* 
      {props.done ? (
        <div className="close">
          <CTA text="Done" icon={<Check />} trigger={props.done} />
        </div>
      ) : null} */}

      <div
        id="modalCenter"
        className={props.fullscreen ? "centerFullscreen" : ""}
      >
        <div
          // className="modalMessage"
          className={props.fullscreen ? "messageFullscreen" : "modalMessage"}
        >
          <h3>{props.message}</h3>
          {props.subMessage ? (
            <span style={{ marginTop: "10px", display: "inline-block" }}>
              {props.subMessage}
            </span>
          ) : null}
        </div>
        <div className="modalContents">{props.contents}</div>
      </div>
    </div>
  ) : null;
}

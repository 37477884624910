import React, { useState } from "react";
import Dashboard from "../../_components/dashboard";
import Header from "../../_components/header";
import "./about.css";
import { Animated } from "react-animated-css";

export default function About(props) {
  console.log(props);
  // const [loggedIn, setLoggedIn] = useState();

  return (
    <>
      <Header
        firebase={props.firebase}
        user={props.firebase.auth().currentUser}
      />
      <div id="main" className="mainTop">
        <div className="section aboutHeader">
          <Animated
            animationIn="fadeInDownBig"
            animationInDelay={0}
            isVisible={true}
          >
            <h3 className="sectionHeader">Get everyone on the same page</h3>
          </Animated>
        </div>
        <div className="section dashboardCon">
          <Dashboard />
        </div>

        <div className="section beta">
          <Animated
            animationIn="fadeIn"
            animationInDelay={400}
            isVisible={true}
          >
            Prescast is now in beta, so be nice!
          </Animated>
        </div>
      </div>
    </>
  );
}

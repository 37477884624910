import React, { useState, useEffect, useRef } from "react";
import Header from "../../_components/header";
import CTA from "../callToAction.js";
import { LogOut, Save, Mail } from "react-feather";
import Loading from "../loading";
import Message from "../message";
import { valEmail, valUsername, valNotEmpty } from "../_auth/authActions";

import { Animated } from "react-animated-css";

export default function Account(props) {
  document.title = `Prescast - My Projects`;

  const [loggedIn, setLoggedIn] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [passwordAttempt, setPasswordAttempt] = useState(false);

  const [feedbackEmail, setFeedbackEmail] = useState({
    visible: false,
    feedback: { text: "", colour: "var(--dark)" },
  });
  const [feedbackUsername, setFeedbackUsername] = useState({
    visible: false,
    feedback: { text: "", colour: "var(--dark)" },
  });

  const [userAuth, setUserAuth] = useState();
  const [userInfo, setUserInfo] = useState();

  const [inputEmail, setInputEmail] = useState({
    valid: false,
    feedback: "",
    value: "",
  });

  const [inputPassword, setInputPassword] = useState({
    valid: false,
    feedback: "",
    value: "",
  });

  const [inputUsername, setInputUsername] = useState({
    valid: false,
    feedback: "",
    value: "",
  });

  let passwordRef = React.createRef();
  let usernameRef = React.createRef();
  let emailRef = React.createRef();

  useEffect(() => {
    console.log("userAuth");
    console.log(userAuth);
  }, [userAuth]);

  useEffect(() => {
    console.log("userAuth");
    console.log(userAuth);
  }, [userInfo]);

  useEffect(() => {
    console.log("inputUsername");
    console.log(inputUsername);
  }, [inputUsername]);

  const initAuth = useRef(false);
  if (!initAuth.current) {
    props.firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        console.log("signed in");

        setUserAuth(user);
        setUserAuth(user);
        console.log(user);
        console.log(user.displayName);

        setInputUsername({
          valid: true,
          feedback: "",
          value: user.displayName,
        });

        setInputEmail({
          valid: true,
          feedback: "",
          value: user.email,
        });

        props.firebase
          .firestore()
          .collection("user")
          .doc(user.uid)
          .onSnapshot((doc) => {
            if (doc.exists) {
              if (JSON.stringify(userInfo) !== JSON.stringify(doc.data())) {
                setUserInfo(doc.data());
              }
            }
          });
      } else {
        console.log("No user");
        return window.location.replace("/about");
      }
    });
    initAuth.current = true;
  }

  const changePassword = () => {
    props.firebase
      .auth()
      .sendPasswordResetEmail(userAuth.email)
      .then(function () {
        // Email sent.
        alert("check your email");
      })
      .catch(function (error) {
        // An error happened.
        alert(error);
        console.log(error);
      });
  };

  const checkValEmail = () => {
    console.log("checkValEmail");

    ////////// EMAIL VALID?
    setInputEmail(valEmail(emailRef.current.value));

    if (inputEmail.valid && emailRef.current.value !== userAuth.email) {
      setFeedbackEmail({
        visible: true,
        feedback: { text: "", colour: "var(--dark)" },
      });
    } else {
      setFeedbackEmail({
        visible: false,
        feedback: { text: "", colour: "var(--dark)" },
      });
    }
  };

  const checkValPassword = () => {
    console.log("checkValPassword");

    ////////// PASSWORD VALID?
    setInputPassword(valNotEmpty(passwordRef.current.value));
  };

  const checkValUsername = () => {
    console.log("checkValUsername");

    ////////// USERNAME VALID?
    setInputUsername(valUsername(usernameRef.current.value));

    if (
      inputUsername.valid &&
      usernameRef.current.value !== userAuth.displayName
    ) {
      setFeedbackUsername({
        visible: true,
        feedback: { text: "", colour: "var(--dark)" },
      });
    } else {
      setFeedbackUsername({
        visible: false,
        feedback: { text: "", colour: "var(--dark)" },
      });
    }
  };

  const updateEmail = () => {
    console.log("updateEmail");

    setPasswordAttempt(true);
    setInputEmail(valEmail(emailRef.current.value));
    setInputPassword(valNotEmpty(passwordRef.current.value));

    // ALL VALID
    if (inputEmail.valid && inputPassword.valid) {
      setFeedbackEmail({
        visible: true,
        feedback: { text: "Saving...", colour: "var(--dark)" },
      });

      props.firebase
        .auth()
        .signInWithEmailAndPassword(userAuth.email, inputPassword.value)
        .then((userCredential) => {
          userCredential.user
            .updateEmail(inputEmail.value)
            .then(() => {
              console.log("Update successful");
              setFeedbackEmail({
                visible: true,
                feedback: { text: "Email saved!", colour: "var(--dark)" },
              });

              setTimeout(() => {
                setFeedbackEmail({
                  visible: false,
                  feedback: { text: "", colour: "var(--dark)" },
                });
              }, 3000);
            })
            .catch(function (error) {
              switch (error.code) {
                case "auth/email-already-in-use":
                  setInputEmail({
                    valid: false,
                    feedback: "Oh! That email is already in use.",
                    value: "",
                  });

                  break;

                case "auth/invalid-email":
                  setFeedbackEmail({
                    visible: true,
                    feedback: {
                      text: "Nope! That email is not right.",
                      colour: "var(--red)",
                    },
                  });

                  setInputEmail({
                    valid: false,
                    feedback: "",
                    value: "",
                  });

                  break;

                default:
                  setFeedbackEmail({
                    visible: true,
                    feedback: {
                      text: "Nope! That's not right.",
                      colour: "var(--red)",
                    },
                  });

                  setInputPassword({
                    valid: false,
                    feedback: "",
                    value: "",
                  });

                  setInputEmail({
                    valid: false,
                    feedback: "",
                    value: "",
                  });
              }

              console.log(error);
            });
        })
        .catch(function (error) {
          switch (error.code) {
            case "auth/user-not-found":
              setFeedbackEmail({
                visible: true,
                feedback: {
                  text: "Oh... something is not right.",
                  colour: "var(--red)",
                },
              });
              break;
            case "auth/too-many-requests":
              setFeedbackEmail({
                visible: true,
                feedback: {
                  text: "Oh too many log in requests! Try later.",
                  colour: "var(--red)",
                },
              });
              break;

            case "auth/wrong-password":
              setFeedbackEmail({
                visible: true,
                feedback: {
                  text: "Nope! That's not right.",
                  colour: "var(--red)",
                },
              });

              setInputPassword({
                valid: false,
                feedback: "",
                value: "",
              });

              break;

            default:
              setFeedbackEmail({
                visible: true,
                feedback: {
                  text: "Nope! That's not right.",
                  colour: "var(--red)",
                },
              });

              setInputPassword({
                valid: false,
                feedback: "",
                value: "",
              });

              setInputEmail({
                valid: false,
                feedback: "",
                value: "",
              });
          }

          console.log(error);
        });
    }
  };

  const updateUsername = () => {
    console.log("updateUsername");

    setInputUsername(valUsername(usernameRef.current.value));

    // ALL VALID
    if (inputUsername.valid === true) {
      setFeedbackUsername({
        visible: true,
        feedback: { text: "Saving...", colour: "var(--dark)" },
      });

      props.firebase
        .auth()
        .currentUser.updateProfile({
          displayName: inputUsername.value,
        })
        .then(function () {
          // Update successful.
          console.log("Update successful");
          setFeedbackUsername({
            visible: true,
            feedback: { text: "Username saved!", colour: "var(--dark)" },
          });

          setTimeout(() => {
            setFeedbackUsername({
              visible: false,
              feedback: { text: "", colour: "var(--dark)" },
            });
          }, 3000);
        })
        .catch(function (error) {
          // An error happened.
          console.log("error", error);
        });
    }
  };

  return (
    <>
      <Header
        firebase={props.firebase}
        user={props.firebase.auth().currentUser}
      />

      <div id="main">
        <div className="section">
          <h2 className="sectionHeader">My Account</h2>
          {userAuth ? (
            messageOpen ? (
              <Message
                mainText="Check your email"
                subText="Didn't get anything? It can take a few mins. Also check your junk folder or try again."
                // timeout={true}
                cta={"Done"}
              />
            ) : (
              <>
                <div className="columnSection">
                  <div className="column3">
                    <div className="subSection">
                      {/* EMAIL ///////////////////////////////////////////////////// */}

                      <h1 className="subSectionHeader">Email</h1>

                      <label
                        className={
                          inputEmail.valid === false &&
                          inputEmail.feedback !== ""
                            ? "invalid"
                            : ""
                        }
                      >
                        {inputEmail.feedback}
                      </label>
                      <input
                        name="email"
                        ref={emailRef}
                        className={
                          inputEmail.valid === false
                            ? "inputLight invalid"
                            : "inputLight"
                        }
                        onChange={checkValEmail}
                        style={{}}
                        type="text"
                        placeholder={userAuth.email}
                        value={inputEmail.value}
                      />

                      {/* PASSWORD ///////////////////////////////////////////////////// */}

                      <div
                        style={{
                          height: feedbackEmail.visible ? "auto" : "0px",
                          overflow: "hidden",
                        }}
                      >
                        <Animated
                          animationIn="fadeInDown"
                          animationOut="fadeOut"
                          animationInDuration={1000}
                          animationOutDuration={500}
                          isVisible={
                            feedbackEmail.visible && inputEmail.valid
                              ? true
                              : false
                          }
                          animateOnMount={false}
                        >
                          <label
                            className={
                              inputPassword.valid === false &&
                              inputPassword.feedback !== "" &&
                              !passwordAttempt
                                ? "invalid"
                                : ""
                            }
                          >
                            {inputPassword.feedback}
                          </label>
                          <input
                            name="new-password"
                            ref={passwordRef}
                            autoComplete="new-password"
                            onChange={checkValPassword}
                            className={
                              inputPassword.valid === false && passwordAttempt
                                ? "inputLight invalid"
                                : "inputLight"
                            }
                            type="password"
                            placeholder="password"
                          />
                          <p className="minor">
                            Please enter your password to authorize changing
                            your email.
                          </p>
                          <span
                            style={{
                              lineHeight: "40px",
                              color: feedbackEmail.feedback.colour,
                            }}
                          >
                            {feedbackEmail.feedback.text}
                          </span>
                          <div
                            style={{
                              float: "right",
                              margin: "5px 0px",
                              display: "block",
                            }}
                          >
                            <CTA
                              text="Save"
                              icon={<Save />}
                              trigger={updateEmail}
                            />
                          </div>
                        </Animated>
                      </div>
                    </div>

                    {/* USERNAME ///////////////////////////////////////////////////// */}

                    <div className="subSection">
                      <h1 className="subSectionHeader">Username</h1>
                      <label
                        className={
                          inputUsername.valid === false &&
                          inputUsername.feedback !== ""
                            ? "invalid"
                            : ""
                        }
                      >
                        {inputUsername.feedback}
                      </label>
                      <input
                        name="update-username"
                        ref={usernameRef}
                        className={
                          inputUsername.valid === false
                            ? "inputLight invalid"
                            : "inputLight"
                        }
                        onChange={checkValUsername}
                        style={{
                          display: "block",
                        }}
                        type="text"
                        placeholder={
                          userAuth.displayName
                            ? userAuth.displayName
                            : "Username"
                        }
                        value={inputUsername.value}
                      />
                      <span style={{ lineHeight: "40px" }}>
                        {feedbackUsername.feedback.text}
                      </span>
                      <Animated
                        animationIn="fadeInDown"
                        animationOut="fadeOut"
                        animationInDuration={1000}
                        animationOutDuration={500}
                        isVisible={
                          feedbackUsername.visible && inputUsername.valid
                            ? true
                            : false
                        }
                        animateOnMount={false}
                      >
                        <div
                          style={{
                            float: "right",
                            margin: "5px 0px",
                            height: feedbackUsername.visible ? "auto" : "0px",
                          }}
                        >
                          <CTA
                            text="Save"
                            icon={<Save />}
                            trigger={updateUsername}
                          />
                        </div>
                      </Animated>
                    </div>

                    <div className="subSection">
                      <h1 className="subSectionHeader">Change Password</h1>
                      <p className="minor">
                        We'll send an email to change your password.
                      </p>
                      <CTA
                        text="Change Password"
                        icon={<Mail />}
                        trigger={changePassword}
                      />
                    </div>

                    <div className="subSection">
                      <h1 className="subSectionHeader">
                        It's time to say good bye
                      </h1>
                      <CTA
                        text="Log out"
                        icon={<LogOut />}
                        trigger={"./logout"}
                      />
                    </div>
                  </div>

                  <div className="column3-2">
                    <h1></h1>
                  </div>
                </div>
              </>
            )
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
// import { BrowserRouter as Redirect } from 'react-router-dom';

import CTA from "../callToAction.js";
// import { Plus, LogOut } from 'react-feather';

import KeyboardEventHandler from "react-keyboard-event-handler";
import { valEmail, valNotEmpty } from "../_auth/authActions";

import { Link } from "react-router-dom";
import * as ROUTES from "../../routes";
import "../../_css/forms.css";

export default function LoginForm(props) {
  document.title = `Prescast - Login`;

  const [loggedIn, setLoggedIn] = useState();
  const [attempt, setAttempt] = useState(false);

  useEffect(() => {
    console.log(loggedIn);
  }, [loggedIn]);

  const [inputEmail, setInputEmail] = useState({
    valid: false,
    feedback: "",
    value: "",
  });

  const [inputPassword, setInputPassword] = useState({
    valid: false,
    feedback: "",
    value: "",
  });

  const [formFeedback, setFormFeedback] = useState("");

  let emailRef = React.createRef();
  let passwordRef = React.createRef();

  const handleKeyDown = (key, e) => {
    e.preventDefault();
    if (key === "enter") {
      console.log("handleKeyDown");
      console.log(key);
      submitLogin();
    }
  };

  props.firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      console.log("user ", user);
      if (!user.isAnonymous) {
        setLoggedIn(true);
      }
    } else {
      setLoggedIn(false);
    }
  });

  // ///////////////////////////////////////////////////////////////

  const checkVal = () => {
    console.log("checkVal");

    ////////// EMAIL VALID?
    setInputEmail(valEmail(emailRef.current.value));

    ////////// PASSWORD VALID?
    setInputPassword(valNotEmpty(passwordRef.current.value));
  };

  const submitLogin = () => {
    console.log("submitLogin");

    // INITAL?
    setAttempt(true);

    checkVal();

    // ALL VALID
    if (inputEmail.valid === true && inputPassword.valid === true) {
      props.firebase
        .auth()
        .signInWithEmailAndPassword(inputEmail.value, inputPassword.value)
        .then(function () {
          console.log("requestLogin SENT");
        })
        .catch(function (error) {
          switch (error.code) {
            case "auth/user-not-found":
              setFormFeedback("Oh... we dont have that email");
              break;

            case "auth/too-many-requests":
              setFormFeedback("Oh too many log in requests! Try later.");
              break;

            case "auth/wrong-password":
              setFormFeedback("Nope! That's not right");
              break;

            default:
              setFormFeedback("Nope! That's not right");
          }

          console.log(error);
        });
    }
  };

  return !loggedIn
    ? [
        <KeyboardEventHandler
          handleFocusableElements={true}
          handleKeys={["esc", "enter", "return"]}
          onKeyEvent={(key, e) => handleKeyDown(key, e)}
        />,
        <div>
          <form>
            <label
              className={
                inputEmail.valid === false &&
                inputEmail.feedback !== "" &&
                attempt === true
                  ? "invalid"
                  : ""
              }
            >
              {attempt ? inputEmail.feedback : ""}
            </label>
            <input
              name="email"
              ref={emailRef}
              className={
                inputEmail.valid === false && attempt === true ? "invalid" : ""
              }
              onChange={checkVal}
              type="text"
              autoFocus
              placeholder="your@email.com"
            />

            <label
              className={
                inputPassword.valid === false &&
                inputPassword.feedback !== "" &&
                attempt === true
                  ? "invalid"
                  : ""
              }
            >
              {attempt ? inputPassword.feedback : ""}
            </label>

            <input
              name="password"
              ref={passwordRef}
              className={
                inputPassword.valid === false && attempt === true
                  ? "invalid"
                  : ""
              }
              onChange={checkVal}
              type="password"
              placeholder="password"
            />

            <div id="modalFooter">
              <div id="feedback">{formFeedback ? formFeedback : null}</div>

              <div style={{ float: "right" }}>
                <CTA text="Log in" trigger={submitLogin} />
              </div>

              <Link
                to={ROUTES.SIGNUP}
                className="nextToButtonRight minorLink"
                style={{ float: "right" }}
              >
                Sign up
              </Link>
              <Link
                to={ROUTES.FORGOT}
                className="nextToButtonRight minorLink"
                style={{ float: "right" }}
              >
                Forgot?
              </Link>
            </div>
          </form>
        </div>,
      ]
    : window.location.replace("/projects");
}

import React from "react";
import { Link } from "react-router-dom";

export default function CTA(props) {
  // <CTA text="Login" trigger={submitLogin} />
  // <CTA text="Login" trigger="/index" />

  console.log(props);
  console.log(props.alt);

  var addClass = "";

  // CTA NOT VALID
  if (props.valid === false) {
    addClass += "disabled ";
  }

  // CTA CIRCLE
  if (props.text === "" || props.text === undefined) {
    addClass += "ctaCircle ";
  } else {
    addClass += "cta ";
  }

  // CTA MINOR
  if (props.minor === true) {
    addClass += "ctaMinor ";
  }

  // CTA DARK
  if (props.dark === true) {
    addClass += "ctaDark ";
  }

  // CTA FULL WIDTH
  if (props.fullWidth === true) {
    addClass += "ctaFullWidth ";
  }

  if (typeof props.trigger !== "string") {
    return (
      <a className={addClass} onClick={props.trigger}>
        {props.alt === undefined ? props.icon : null}
        {props.text && props.text !== "" ? props.text : null}
        {!props.alt !== undefined ? null : props.icon}
      </a>
    );
  } else {
    return (
      <Link to={props.trigger} className={addClass}>
        {props.alt === undefined ? props.icon : null}
        {props.text && props.text !== "" ? props.text : null}
        {!props.alt !== undefined ? null : props.icon}{" "}
      </Link>
    );
  }

  // if (props.text === undefined || props.text === "") {
  //   /////////////////////////////////////////////////////////////////////////////
  //   // CTA CIRCLE
  //   if (typeof props.trigger !== "string") {
  //     return (
  //       <a
  //         className={
  //           (props.valid === false ? "disabled " : "") +
  //           (props.dark ? "ctaDark" : "") +
  //           " ctaCircle"
  //         }
  //         onClick={props.trigger}
  //       >
  //         {props.icon}
  //       </a>
  //     );
  //   } else {
  //     return (
  //       <Link
  //         to={props.trigger}
  //         className={
  //           (props.valid === false ? "disabled " : "") +
  //           (props.dark ? "ctaDark" : "") +
  //           " ctaCircle"
  //         }
  //       >
  //         {props.icon}
  //       </Link>
  //     );
  //   }
  // } else {
  //   if (props.altLayout === false) {
  //     /////////////////////////////////////////////////////////////////////////////
  //     // ICON AND TEXT RIGHT
  //     if (typeof props.trigger !== "string") {
  //       return (
  //         <a
  //           className={
  //             (props.valid === false ? "disabled " : "") +
  //             (props.dark ? "ctaDark" : "") +
  //             " cta altLayout"
  //           }
  //           onClick={props.trigger}
  //         >
  //           {props.text}
  //           {props.icon}
  //         </a>
  //       );
  //     } else {
  //       return (
  //         <Link
  //           to={props.trigger}
  //           className={
  //             (props.valid === false ? "disabled " : "") +
  //             (props.dark ? "ctaDark" : "") +
  //             " cta altLayout"
  //           }
  //         >
  //           {props.text}
  //           {props.icon}
  //         </Link>
  //       );
  //     }
  //   } else {
  //     /////////////////////////////////////////////////////////////////////////////
  //     // ICON AND TEXT LEFT

  //     if (typeof props.trigger !== "string") {
  //       return (
  //         <a
  //           className={
  //             (props.valid === false ? "disabled " : "") +
  //             (props.dark ? "ctaDark" : "") +
  //             " cta"
  //           }
  //           onClick={props.trigger}
  //         >
  //           {props.icon}
  //           {props.text}
  //         </a>
  //       );
  //     } else {
  //       return (
  //         <Link
  //           to={props.trigger}
  //           className={
  //             (props.valid === false ? "disabled " : "") +
  //             (props.dark ? "ctaDark" : "") +
  //             " cta"
  //           }
  //         >
  //           {props.icon}
  //           {props.text}
  //         </Link>
  //       );
  //     }
  //   }
  // }
}

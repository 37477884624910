import React, { useState, useRef } from "react";

import CTA from "../callToAction.js";
import { Plus, X } from "react-feather";

import "../../_css/thumbs.css";
import "../../_css/projects.css";
import Thumb from "../project/thumb.js";
import { setPage } from "../project/projectActions";

export default function ProjectOverview(props) {
  const [project, setProject] = useState(false);
  console.log(props);

  const setPageTo = (newPage) => {
    setPage(props.firebase, props.projectID, project, newPage);
  };

  const getInit = useRef(false);
  if (!getInit.current) {
    props.firebase
      .firestore()
      .collection("content")
      .doc(props.projectID)
      .onSnapshot((doc) => {
        if (doc.exists) {
          console.log(project);
          console.log(doc.data());
          setProject(doc.data());
        }
      });
    getInit.current = true;
  }

  return (
    <div className="projectOverview">
      <div className="overviewTools">
        <a href={"./:" + props.projectID} target="_new">
          {"# " + props.projectID}
        </a>
      </div>
      <a href={"./:" + props.projectID} target="_new">
        <div
          className="projectThumbs scrollBar scrollBarLight"
          onScroll={(event) => {
            console.log(event);
            event.preventDefault();
          }}
        >
          {project.projectOrder && project.projectOrder.length > 0 ? (
            project.projectOrder.map((data, index) => {
              if (
                project.itemList[data] !== undefined &&
                project.itemList !== undefined
              ) {
                console.log(data);

                var itemID = data;
                if (project.itemList[data + "_LR"]) {
                  itemID = data + "_LR";
                }

                return (
                  <Thumb
                    itemID={itemID}
                    itemData={project.itemList[itemID]}
                    highlighted={
                      project.pageNumber - 1 === index ? true : false
                    }
                    pageNumber={project.pageNumber}
                    itemNumber={index}
                    setPage={setPageTo}
                  />
                );
              }
            })
          ) : (
            <Thumb blank={true} />
          )}
        </div>
      </a>
    </div>
  );
}

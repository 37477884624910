import React, { useState, useEffect, useRef } from "react";

// ///////////////////////////////////////////////////////////////////////////

import randomize from "randomatic";

export default function Test(props) {
  console.log(props);
  // const [pageTotal, setPageTotal] = useState(null);

  const addVideo = () => {
    var itemID = randomize("aA0", 8);

    props.firebase
      .firestore()
      .collection("content")
      .doc("YqsGdTkH")
      .set(
        {
          itemList: {
            [itemID]: {
              contentType: "video",
              smartPage: true,
              url: "",
              autoPlay: true,
              controls: false,
              loop: false,
              muted: true,
            },
          },
          projectOrder: props.firebase.firestore.FieldValue.arrayUnion(
            ...[itemID]
          ),
        },
        { merge: true }
      ); // console.log("update");
  };

  const addLayout = () => {
    console.log("update");
    var itemID = randomize("aA0", 8);

    props.firebase
      .firestore()
      .collection("content")
      .doc("YqsGdTkH")
      .set(
        {
          itemList: {
            [itemID]: {
              contentType: "dinnerTrayRight",
              smartPage: true,
              urls: [],
            },
          },
          projectOrder: props.firebase.firestore.FieldValue.arrayUnion(
            ...[itemID]
          ),
        },
        { merge: true }
      );
  };

  // // //////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div style={{ margin: "50px" }}>
        <button onClick={addVideo}>ADD VIDEO</button>
      </div>

      <div style={{ margin: "50px" }}>
        <button
          onClick={() => {
            addLayout();
          }}
        >
          ADD LAYOUT
        </button>
      </div>
    </>
  );
}

import React from "react";
import "../_css/loading.css";

export default function Loading(props) {
  switch (props.mode) {
    case "fullscreen":
      return (
        <>
          {props.message ? <h1>{props.message}</h1> : null}
          <div className="spinnerFullScreen">
            <div className="double-bounce1FullScreen"></div>
            <div className="double-bounce2FullScreen"></div>
          </div>
        </>
      );

    default:
      return (
        <>
          {props.message ? (
            <div className="spinnerMessageCon">
              <h1 className="loadingMessage">{props.message}</h1>
              <div className="spinnerMessage">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
              </div>
            </div>
          ) : (
            <div className="spinner">
              <div className="double-bounce1"></div>
              <div className="double-bounce2"></div>
            </div>
          )}
        </>
      );
  }
}

import React, { useState, useEffect, useRef } from "react";

// ///////////////////////////////////////////////////////////////////////////

import randomize from "randomatic";

export default function CreateNewFolio(props) {
  console.log(props);
  const [newLink, set_newLink] = useState(null);

  const createNew = async () => {
    console.log("update");
    var newID = randomize("aA0", 8);

    const folioRef = props.firebase
      .firestore()
      .collection("content")
      .doc("fYJaZy9w");
    const doc = await folioRef.get();
    if (!doc.exists) {
      console.log("No such document!");
    } else {
      console.log("Document data:", doc.data());

      props.firebase
        .firestore()
        .collection("content")
        .doc(newID)
        .set(doc.data(), { merge: true });
    }

    set_newLink("https://prescast.com/:" + newID);
  };

  // // //////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div style={{ margin: "50px" }}>
        <button onClick={createNew}>Create Folio</button>
        <h1>
          <a href={newLink}>{newLink}</a>
        </h1>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";

import CTA from "../callToAction.js";

import KeyboardEventHandler from "react-keyboard-event-handler";
import { valEmail } from "../_auth/authActions";

import { Link } from "react-router-dom";
import * as ROUTES from "../../routes";
import Message from "../message";

export default function LoginForm(props) {
  document.title = `Prescast - Forgotten Password`;

  const [loggedIn, setLoggedIn] = useState(false);
  const [attempt, setAttempt] = useState(false);

  const [messageOpen, setMessageOpen] = useState(false);

  const [inputEmail, setInputEmail] = useState({
    valid: false,
    feedback: "",
    value: "",
  });

  const [formFeedback, setFormFeedback] = useState("");

  let emailRef = React.createRef();

  const handleKeyDown = (key, e) => {
    e.preventDefault();

    if (key === "enter") {
      console.log("handleKeyDown");
      console.log(key);
      submitForgot();
    }
  };

  if (props.user) {
    if (loggedIn === false) {
      setLoggedIn(true);
    }
  }

  props.firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      if (loggedIn === false) {
        setLoggedIn(true);
      }
    }
  });

  // ///////////////////////////////////////////////////////////////

  const checkVal = () => {
    console.log("checkVal");

    ////////// EMAIL VALID?
    setInputEmail(valEmail(emailRef.current.value));
  };

  const submitForgot = () => {
    console.log("submitForgot");

    // INITAL?
    setAttempt(true);

    checkVal();

    // ALL VALID
    if (inputEmail.valid === true) {
      console.log("SEND");
      setFormFeedback("valid");
      props.firebase
        .auth()
        .sendPasswordResetEmail(emailRef.current.value)
        .then(function () {
          // Email sent.
          console.log("requestReset SENT");
          setMessageOpen(true);
        })
        .catch(function (error) {
          // ERROR

          switch (error.code) {
            case "auth/user-not-found":
              setInputEmail({
                valid: false,
                feedback: "Oh... we dont have that email",
              });
              break;
            // case 'auth/wrong-password':
            //   setFormFeedback('Nope! That password is not right');
            //   break;
            default:
              setInputEmail({
                valid: false,
                feedback: "Oh... we dont have that email",
              });
          }

          console.log(error);
        });
    }
  };

  return !props.user
    ? [
        <KeyboardEventHandler
          handleFocusableElements={true}
          handleKeys={["esc", "enter", "return"]}
          onKeyEvent={(key, e) => handleKeyDown(key, e)}
        />,

        messageOpen ? (
          <Message
            mainText="Check your email"
            subText="Didn't get anything? It can take a few mins. Also check your junk folder or try again."
            // timeout={true}
            cta={"Done"}
            trigger={"/login"}
          />
        ) : null,
        <div>
          <form>
            <p className="minor">
              Enter your email and we'll
              <br />
              send you a password reset.
            </p>

            <label
              className={
                inputEmail.valid === false &&
                inputEmail.feedback !== "" &&
                attempt === true
                  ? "invalid"
                  : ""
              }
            >
              {attempt ? inputEmail.feedback : ""}
            </label>
            <input
              name="email"
              ref={emailRef}
              className={
                inputEmail.valid === false && attempt === true ? "invalid" : ""
              }
              onChange={checkVal}
              type="text"
              autoFocus
              placeholder="your@email.com"
            />

            <div id="modalFooter">
              <div id="feedback">
                {/* {formFeedback ? formFeedback : null} */}
              </div>
              <div style={{ float: "right" }}>
                <CTA text="Send" trigger={submitForgot} />
              </div>

              <Link
                to={ROUTES.LOGIN}
                className="nextToButtonRight minorLink"
                style={{ float: "right" }}
              >
                Login
              </Link>
            </div>
          </form>
        </div>,
      ]
    : window.location.replace("/login");
}

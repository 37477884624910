import React, { useState } from "react";

import CTA from "../callToAction";
import Loading from "../loading";
import Cookies from "js-cookie";

export default function Terms(props) {
  document.title = `Prescast - Terms`;
  console.log(props);
  const [loading, setLoading] = useState(false);

  // ///////////////////////////////////////////////////////////////
  console.log(props);

  const agreeLegal = () => {
    console.log("props");
    console.log(props);
    if (!props.user) {
      console.log("agreeLegal");
      console.log(props);
      setLoading(true);
      props.firebase
        .auth()
        .signInAnonymously()
        .catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(props);

          // ...
        });
    }
  };
  // ///////////////////////////////////////////////////////////////

  return [
    <div id="modalFooter">
      <div style={{ float: "right", marginLeft: 7 }}>
        <CTA text="I Agree" trigger={agreeLegal} />
      </div>
      By continuing you agree to our{" "}
      <a href="./terms" target="_new">
        policy, terms and conditions
      </a>
    </div>
  ];
}

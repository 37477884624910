import React, { useState } from "react";
// import { BrowserRouter as Redirect } from 'react-router-dom';

import CTA from "../callToAction.js";
// import { Plus, LogOut } from 'react-feather';

import KeyboardEventHandler from "react-keyboard-event-handler";
import { valEmail, valNotEmpty, valPassword } from "../_auth/authActions";

import { Link } from "react-router-dom";
import * as ROUTES from "../../routes";
import "../../_css/forms.css";
import Message from "../message";

export default function PasswordChange(props) {
  document.title = `Prescast - Change Password`;

  const [loggedIn, setLoggedIn] = useState(false);
  const [attempt, setAttempt] = useState(false);

  const [inputPassword, setInputPassword] = useState({
    valid: false,
    feedback: "",
    value: "",
  });

  const [messageOpen, setMessageOpen] = useState(false);

  const [formFeedback, setFormFeedback] = useState("");

  let passwordRef = React.createRef();

  const handleKeyDown = (key, e) => {
    e.preventDefault();
    if (key === "enter") {
      console.log("handleKeyDown");
      console.log(key);
      submitPasswordChange();
    }
  };

  if (props.user) {
    if (loggedIn === false) {
      setLoggedIn(true);
    }
  }
  console.log(props);

  props.firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      if (loggedIn === false) {
        setLoggedIn(true);
      }
    }
  });

  // ///////////////////////////////////////////////////////////////

  const checkVal = () => {
    console.log("checkVal");

    ////////// EMAIL VALID?
    setInputPassword(valPassword(passwordRef.current.value));
  };

  const submitPasswordChange = () => {
    console.log("submitPasswordChange");

    // INITAL?
    setAttempt(true);

    checkVal();

    // ALL VALID
    if (inputPassword.valid === true) {
      props.firebase
        .auth()
        .verifyPasswordResetCode(props.actionData.oobCode)
        .then(function (email) {
          console.log(email);

          // SAVE NEW PASSWORD
          props.firebase
            .auth()
            .confirmPasswordReset(props.actionData.oobCode, inputPassword.value)
            .then(function (resp) {
              // SIGN IN WITH NEW LOGIN
              props.firebase
                .auth()
                .signInWithEmailAndPassword(email, inputPassword.value)
                .then(function () {
                  setMessageOpen({
                    mainText: "Password updated successfully.",
                    timeout: true,
                    trigger: "/projects",
                  });
                });
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (error) {
          // alert(error);
          console.log(error);
          if (error.code === "auth/invalid-action-code") {
            setMessageOpen({
              mainText: "Code expired, lets try again...",
              timeout: true,
              trigger: "/forgot",
            });
          } else {
            alert(error.code);
          }
          return;
          // Invalid or expired action code. Ask user to try to reset the password
          // again.
        });
    }
  };

  return !props.user
    ? [
        <KeyboardEventHandler
          handleFocusableElements={true}
          handleKeys={["esc", "enter", "return"]}
          onKeyEvent={(key, e) => handleKeyDown(key, e)}
        />,
        messageOpen ? (
          <Message
            mainText={messageOpen.mainText}
            // subText="Loggin you in"
            timeout={messageOpen.timeout}
            // cta={'Done'}
            trigger={messageOpen.trigger}
          />
        ) : null,
        <div>
          <form autoComplete="off">
            <p className="minor">Choose your new password</p>

            <label
              className={
                inputPassword.valid === false &&
                inputPassword.feedback !== "" &&
                attempt === true
                  ? "invalid"
                  : ""
              }
            >
              {attempt ? inputPassword.feedback : ""}
            </label>
            <input
              name="new-password"
              autoComplete="new-password"
              type="password"
              placeholder="new password"
              className={
                inputPassword.valid === false && attempt === true
                  ? "invalid"
                  : ""
              }
              onChange={checkVal}
              ref={passwordRef}
            />
            <div id="modalFooter">
              <div id="feedback">{formFeedback ? formFeedback : null}</div>

              <div style={{ float: "right", marginLeft: 7 }}>
                <CTA text="Change" trigger={submitPasswordChange} />
              </div>

              <Link
                to={ROUTES.LOGIN}
                className="nextToButtonRight"
                style={{ float: "right" }}
              >
                Log in
              </Link>
            </div>
          </form>
        </div>,
      ]
    : window.location.replace("/projects");
}
